import React, { useState } from 'react';
import { RatioConfig } from '../../types/RatioConfig';
import { FieldItem } from '../Common/FieldItem/FieldItem';
import { useGetRatioConfigs } from '../../utils/hooks/useGetRatioConfigs';

interface RatioSelectorProps {
  highlightedRatio: string;
  setHighlightedRatio: (ratio: string) => void;
}

export const RatioSelector: React.FC<RatioSelectorProps> = ({ highlightedRatio, setHighlightedRatio }) => {
  const [ratioDescription, setRatioDescription] = useState<string>('');
  const [ratioResult, ] = useState<string | null>(null);

  const { ratioConfigs, loading, error } = useGetRatioConfigs();

  const handleRatioHighlight = async (ratioName: string) => {
    setHighlightedRatio(ratioName);
    const selectedConfig = ratioConfigs.find((config: RatioConfig) => config.name === ratioName);
    if (selectedConfig) { 
      setRatioDescription(selectedConfig.description);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="grid grid-cols-2 gap-6">
      <div className="flex flex-col">
        <p className="mb-2 font-semibold leading-none text-gray-900">Select a Ratio</p>
        <dl className="p-4 border border-gray-200 rounded-lg bg-gray-50 mb-2 h-64 overflow-y-auto">
          {ratioConfigs.map((config: RatioConfig, index: number) => (
            <FieldItem key={index} index={index} field={config.name} handleFieldSelect={() => handleRatioHighlight(config.name)} />
          ))}
        </dl>
      </div>
      <dl>
        <dt className="mb-4 font-semibold leading-none text-gray-900">{highlightedRatio}</dt>
        <dd className="font-light text-gray-600">
          {ratioDescription}
        </dd>
        {ratioResult !== null && (
          <dd className="mt-4 font-medium text-gray-800">
            {highlightedRatio}: {ratioResult}
          </dd>
        )}
      </dl>
    </div>
  );
};
