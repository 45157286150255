import { OptionDetail } from '../../types/OptionChainType';
import { calculatePutReturnToExpiry } from '../math/calculatePutReturnToExpiry';

export const filterByMinimumReturn = (minimumReturn: number, riskFreeRate: number) => (option: OptionDetail): boolean => {
    const strikePriceNumber = option.details.strike_price;
    const midNumber = option.last_quote.midpoint;
    const expirationString = option.details.expiration_date;
    const expirationDate = new Date(expirationString);
    const returnToExpiry = calculatePutReturnToExpiry(strikePriceNumber, midNumber, expirationDate, riskFreeRate);
    return returnToExpiry >= minimumReturn;
};
