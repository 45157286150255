import React from 'react';
import { MainContainer } from '../components/Containers/MainContainer';
import { CompanyTitle } from "../components/Common/CompanyTitle/CompanyTitle";
import { FinancialsNavBar } from "../components/NavBars/FinancialsNavBar/FinancialsNavBar";
import { useCompanyProfile } from '../context/CompanyProfileContext';
import { Footer } from '../components/Common/Footer/Footer';
import { SelectedCellProvider } from '../context/SelectedCellContext';

export const FinancialStatements: React.FC = () => {
  const { companyProfile } = useCompanyProfile();

  return (
    <SelectedCellProvider>
      <MainContainer>
      <CompanyTitle className='text-2xl font-bold text-primary-600 mb-4' />
      {companyProfile && companyProfile.length > 0 && (
          <>
            <p className="text-lg font-bold">Financial Statements</p>
            <FinancialsNavBar />
            <Footer />
          </>
        )}
      </MainContainer>
    </SelectedCellProvider>
  );
};
