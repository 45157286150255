import React, { useEffect, useState } from 'react';
import { getBatchEarningsTranscripts } from '../../../../api/fmp/getBatchEarningsTranscripts';
import { useResearchTicker } from '../../../../context/TickerContext';

interface EarningsTranscriptsListProps {
    onSelect: (url: string, title: string) => void;
}

export const EarningsTranscriptsList: React.FC<EarningsTranscriptsListProps> = ({ onSelect }) => {
    const [documents, setDocuments] = useState<any[]>([]);
    const { researchTicker } = useResearchTicker();

    useEffect(() => {
        const fetchDocuments = async () => {
            try {
                const currentYear = new Date().getFullYear();
                const years = Array.from({ length: 10 }, (v, k) => currentYear - k);
                let allDocuments: any[] = [];

                for (let year of years) {
                    const response = await getBatchEarningsTranscripts(researchTicker, year);
                    if (response.data && Array.isArray(response.data)) {
                        const yearDocuments = response.data.map((doc: any) => ({
                            period: `Q${doc.quarter} ${doc.year}`,
                            year: doc.year,
                            quarter: doc.quarter,
                            link: doc.content // Assuming 'content' is the URL or adjust based on actual structure
                        }));
                        allDocuments = allDocuments.concat(yearDocuments);
                    }
                }

                setDocuments(allDocuments);
            } catch (error) {
                console.error('Failed to fetch documents:', error);
            }
        };

        fetchDocuments();
    }, [researchTicker]);

    return (
        <ul className="space-y-2 h-64 overflow-y-auto">
            {documents.sort((a, b) => {
                if (b.year === a.year) {
                    return b.quarter - a.quarter; // Compare quarters if the years are the same
                }
                return b.year - a.year; // Compare years first
            }).map((doc, index) => (
                <li
                    key={index}
                    className="flex items-center text-sm hover:bg-gray-100 px-2 py-1 cursor-pointer gap-4"
                    onClick={() => onSelect(doc.link, doc.period)}
                >
                    <span className="text-primary-700 font-semibold">Earnings</span>
                    <span className="text-black">{doc.period} Transcript</span>
                </li>
            ))}
        </ul>
    );
}
