import React, { useState } from 'react';
import { RatioSelector } from './RatioSelector';
import { SolidButton } from '../Common/Buttons/SolidButton';

interface SelectRatioProps {
  selectedRatio: string;
  setSelectedRatio: (ratio: string) => void;
  handleClose: () => void;
}

export const SelectRatio: React.FC<SelectRatioProps> = ({ selectedRatio, setSelectedRatio, handleClose }) => {
  const [highlightedRatio, setHighlightedRatio] = useState<string>(selectedRatio);

  const handleSelectRatio = () => {
    setSelectedRatio(highlightedRatio);
    handleClose();
  };

  return (
    <div>
      <RatioSelector highlightedRatio={highlightedRatio} setHighlightedRatio={setHighlightedRatio}/>
      <SolidButton className="mt-4" onClick={handleSelectRatio} content="Select Ratio" />
    </div>
  );
};
