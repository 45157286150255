import React from 'react';
import { WatchlistType } from '../../types/WatchlistType';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

interface WatchlistDropdownListProps {
    watchlists: WatchlistType[];
    handleWatchlistSelect: (watchlist: WatchlistType) => void;
}

export const WatchlistDropdownList: React.FC<WatchlistDropdownListProps> = ({ watchlists, handleWatchlistSelect }) => {
    return (
        <>
            {watchlists.map(wl => (
                <li
                    key={wl.id}
                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex justify-between items-center"
                    onClick={() => handleWatchlistSelect(wl)}
                >
                    <span>{wl.watchlistName}</span>
                    <div className="flex items-center space-x-2">
                        <FontAwesomeIcon icon={faEdit} className="text-gray-500 w-4 h-4 hover:text-primary-600" />
                        <FontAwesomeIcon icon={faTrash} className="text-gray-500 w-4 h-4 hover:text-primary-600" />
                    </div>
                </li>
            ))}
        </>
    );
};
