import React, { useState } from 'react';
import { MainContainer } from '../components/Containers/MainContainer';
import { API_URL } from '../services/config/apiConfig';

export const StreamingPage: React.FC = () => {
  const [liveStream, setLiveStream] = useState<any>(null);

  const handleCreateLiveStream = async () => {
    try {
      const response = await fetch(`${API_URL}/api/create-live-stream`, {
        method: 'POST',
      });
      const data = await response.json();
      console.log('Live stream created:', data);
      setLiveStream(data);
    } catch (error) {
      console.error('Error creating live stream:', error);
    }
  };

  return (
    <MainContainer>
      <button
        onClick={handleCreateLiveStream}
        className="w-48 block text-white bg-primary-700 hover:bg-primary-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-4"
        type="button"
      >
        Launch Live Stream
      </button>
      {liveStream && (
        <div className="mt-8">
          <h2 className="text-2xl font-semibold">Live Stream Details</h2>
          <p><strong>Stream Key:</strong> {liveStream.stream_key}</p>
          <p><strong>Playback ID:</strong> {liveStream.playback_ids[0].id}</p>
          {/* Add a video player component here if needed */}
        </div>
      )}
    </MainContainer>
  );
};
