import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export const LoginPage: React.FC = () => {
  const { loginWithRedirect, logout, isAuthenticated } = useAuth0();

  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  return (
    <div className="flex flex-col items-center justify-center h-full space-y-4">
      <button
        onClick={() => loginWithRedirect()}
        className="px-4 py-2 font-semibold text-white bg-blue-500 rounded-md"
      >
        Log In
      </button>
      {isAuthenticated && (
        <button
          onClick={handleLogout}
          className="px-4 py-2 font-semibold text-white bg-red-500 rounded-md"
        >
          Log Out
        </button>
      )}
    </div>
  );
};
