import React from 'react';
import { CurrentReportsList } from "./CurrentReportsList";

interface CurrentReportsProps {
    onSelect: (url: string, title: string) => void;
}

export const CurrentReports: React.FC<CurrentReportsProps> = ({ onSelect }) => {
    return (
        <div className="flex flex-col p-4 bg-white rounded-lg shadow-md">
            <div className="mb-4">
                <h4 className="text-xl font-semibold">Current Reports</h4>
                <p className="text-sm text-gray-500">8-Ks</p>
            </div>
            <CurrentReportsList onSelect={onSelect} />
        </div>
    );
}
