import { MetricFieldList } from "../../../types/MetricFieldList";
import { RatioConfig } from "../../../types/RatioConfig";
import { API_URL } from "../../../services/config/apiConfig";

// Extract keys from MetricFieldList
type MetricKeys = typeof MetricFieldList[number]['key'];

// Function to add a new ratio configuration
export const addRatioConfig = async (
  currentRatioConfigs: RatioConfig[],
  name: string,
  numerator: MetricKeys,
  denominator: MetricKeys,
  format: 'number' | 'percentage' | 'currency',
  description: string
): Promise<void> => {
  const newRatioConfig: RatioConfig = { name, numerator, denominator, format, description };

  // Combine the current ratio configs with the new ratio config
  const updatedRatioConfigs = [...currentRatioConfigs, newRatioConfig];

  try {
    const response = await fetch(`${API_URL}/api/post/ratio-configs`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ type: 'ratioConfigs', ratios: updatedRatioConfigs })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log('Ratio configuration added successfully:', data);
  } catch (error) {
    console.error('Error adding ratio configuration:', error);
  }
};
