import React, { createContext, useContext, useState, ReactNode, Dispatch, SetStateAction } from 'react';

interface CompanyProfileContextType {
  companyProfile: any[];
  setCompanyProfile: Dispatch<SetStateAction<any[]>>;
  error: Error | null;
  setError: Dispatch<SetStateAction<Error | null>>;
}

const CompanyProfileContext = createContext<CompanyProfileContextType | undefined>(undefined);

export const CompanyProfileProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [companyProfile, setCompanyProfile] = useState<any[]>([]);
  const [error, setError] = useState<Error | null>(null);

  return (
    <CompanyProfileContext.Provider value={{ companyProfile, setCompanyProfile, error, setError }}>
      {children}
    </CompanyProfileContext.Provider>
  );
};

export const useCompanyProfile = () => {
  const context = useContext(CompanyProfileContext);
  if (!context) {
    throw new Error('useCompanyProfile must be used within a CompanyProfileProvider');
  }
  return context;
};
