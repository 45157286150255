import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { ProtectedLayout } from './ProtectedLayout';

interface ProtectedRouteProps {
  element: React.ReactNode;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
  const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    loginWithRedirect();
    return null; // Render nothing while redirecting
  }

  return <ProtectedLayout>{element}</ProtectedLayout>;
};
