interface NavBarLinkProps<T> {
    name: string;
    id: T;
    setActiveTab: (tab: T) => void;
    isActive: (tabName: T) => string;
};

export const NavBarLink = <T extends string>({ name, id, setActiveTab, isActive }: NavBarLinkProps<T>) => {
    return (
        <button onClick={() => setActiveTab(id)} className={`inline-block p-4 border-b-2 rounded-t-lg ${isActive(id)}`}>
            {name}
        </button>
    );
};
