import React from 'react';
import { NavBarLink } from "../NavBars/NavBarLink";

type TabKey = 'ratios' | 'new_ratio';

interface MetricModalHeaderProps {
    handleModalToggle: () => void;
    activeTab: TabKey;
    setActiveTab: (tab: TabKey) => void;
    isActive: (tabName: TabKey) => string;
}

export const MetricModalHeader: React.FC<MetricModalHeaderProps> = ({ handleModalToggle, activeTab, setActiveTab, isActive }) => {
    return (
        <>
            <div className="flex items-center justify-between pb-4 rounded-t">
                <div className="flex space-x-4">
                    <NavBarLink
                        name="Select Ratio"
                        id="ratios"
                        setActiveTab={setActiveTab}
                        isActive={isActive}
                    />
                    <NavBarLink
                        name="Create New Ratio"
                        id="new_ratio"
                        setActiveTab={setActiveTab}
                        isActive={isActive}
                    />
                </div>
                <button
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 inline-flex"
                    onClick={handleModalToggle}
                >
                    <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        />
                    </svg>
                    <span className="sr-only">Close modal</span>
                </button>
            </div>
        </>
    );
};
