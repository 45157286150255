import axios from 'axios';
import { API_URL } from '../../services/config/apiConfig';
export interface NewsArticle {
  news_url: string;
  image_url: string;
  title: string;
  text: string;
  source_name: string;
  date: string;
  topics: string[];
  sentiment: string;
  type: string;
  tickers: string[];
}

export const getCompanyNews = async (ticker: string): Promise<NewsArticle[]> => {
  try {
    const response = await axios.get<{ data: NewsArticle[] }>(`${API_URL}/api/news?ticker=${ticker}`);
    return response.data.data; // Adjust this based on the actual structure of the response
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('Error fetching company news:', error.response ? error.response.data : error.message);
    } else {
      console.error('Unexpected error:', error);
    }
    throw error;
  }
};
