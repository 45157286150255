export type Units = 'in trillions' | 'in billions' | 'in millions' | 'in thousands' | 'as is' | 'N/A';

export const determineUnits = (value: number | undefined): Units => {
    if (value === undefined || isNaN(value)) return 'N/A';
  
    const absValue = Math.abs(value);
  
    if (absValue >= 1e12) {
      return 'in trillions';
    } else if (absValue >= 1e9) {
      return 'in billions';
    } else if (absValue >= 1e6) {
      return 'in millions';
    } else if (absValue >= 1e3) {
      return 'in thousands';
    } else {
      return 'as is';
    }
  };
  