import React, { useState, useEffect, useRef } from 'react';
import { PeriodType } from '../../../types/PeriodType';

interface DropdownProps {
  value: string;
  onChange: (value: PeriodType) => void;
  options: PeriodType[];
  className?: string;
}

export const PeriodDropdown: React.FC<DropdownProps> = ({ value, onChange, options, className }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className="relative flex items-center justify-center">
      <button
        id="dropdownDefault"
        data-dropdown-toggle="dropdown"
        className={`text-gray-700 hover:text-gray-600 font-medium text-md text-center inline-flex items-center ${className}`}
        type="button"
        onClick={toggleDropdown}
      >
        {value.charAt(0).toUpperCase() + value.slice(1)}
        <svg
          className="w-4 h-4 ml-2"
          aria-hidden="true"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          ></path>
        </svg>
      </button>

      {dropdownOpen && (
        <div
          id="dropdown"
          className="z-10 w-56 p-3 bg-white rounded-lg shadow absolute mt-2"
        >
          <ul className="space-y-2 text-sm">
            {options.map(option => (
              <li className="flex items-center" key={option}>
                <button
                  type="button"
                  className={`flex items-center text-sm font-medium text-gray-900 hover:bg-gray-100 w-full px-1.5 py-1 rounded-md ${value === option ? 'bg-gray-200' : ''}`}
                  onClick={() => {
                    onChange(option);
                    toggleDropdown();
                  }}
                >
                  {option.charAt(0).toUpperCase() + option.slice(1)}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
