import { Logo } from "../Logo/Logo";

export const Footer = () => {
  return <>
        <footer className="w-full border-t border-gray-200 p-4 bg-white md:p-8 lg:p-10 dark:bg-gray-800 mt-16">
            <div className="mx-auto max-w-screen-xl text-center">
                <a href='www.enryched.com' className="flex justify-center items-center">
                    <Logo />
                </a>
                <p className="my-6 text-gray-500 dark:text-gray-400">The Community Research Platform.</p>
                <ul className="flex flex-wrap justify-center items-center mb-6 text-gray-900 dark:text-white">
                    <li>
                        <a href='www.enryched.com' className="mr-4 hover:underline md:mr-6 ">About</a>
                    </li>
                    <li>
                        <a href='www.enryched.com' className="mr-4 hover:underline md:mr-6">Social</a>
                    </li>
                    <li>
                        <a href='www.enryched.com' className="mr-4 hover:underline md:mr-6 ">Subscribe</a>
                    </li>
                    <li>
                        <a href='www.enryched.com' className="mr-4 hover:underline md:mr-6">Blog</a>
                    </li>
                    <li>
                        <a href='www.enryched.com' className="mr-4 hover:underline md:mr-6">Affiliate Program</a>
                    </li>
                    <li>
                        <a href='www.enryched.com' className="mr-4 hover:underline md:mr-6">FAQs</a>
                    </li>
                    <li>
                        <a href='www.enryched.com' className="mr-4 hover:underline md:mr-6">Contact</a>
                    </li>
                </ul>
                <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2023-2024 <a href='www.enryched.com' className="hover:underline">Enryched</a>. All Rights Reserved.</span>
            </div>
        </footer>
    </>
}