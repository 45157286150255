import React from 'react';
import { NewsArticle } from '../../../api/stocknewsapi/getCompanyNews';

interface NewsCardProps {
  article: NewsArticle;
}

export const NewsCard: React.FC<NewsCardProps> = ({ article }) => {
  return (
    <a href={article.news_url} target="_blank" rel="noopener noreferrer" className="group flex flex-col w-full p-4 border rounded-lg shadow-md hover:bg-gray-50 no-underline">
      <h2 className="text-lg font-bold text-black mb-4 group-hover:text-blue-500">{article.title}</h2>
      <p className="text-sm font-bold text-gray-700 mb-1">{article.source_name}</p>
      <p className="text-sm text-gray-600 mb-2">{new Date(article.date).toLocaleDateString()}</p>
      <p className="text-md text-gray-700">{article.text}</p>
    </a>
  );
};
