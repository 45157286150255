import React, { useEffect, useState, useMemo } from 'react';
import { OptionsTableHeader } from './OptionsTableHeader';
import { OptionsTableRow } from "./OptionsTableRow";
import { useOptionChains } from '../../context/OptionChainsContext';
import { filterByExpirationAfter } from '../../utils/filters/filterByExpiration';
import { filterByMinimumReturn } from '../../utils/filters/filterByMinimumReturn';
import { filterByMinimumDiscount } from '../../utils/filters/filterByMinimumDiscount';
import { filterMinimumBid } from '../../utils/filters/filterMinimumBid';
import { useRiskFreeRate } from '../../context/RiskFreeRateContext';
import { getTreasuryRates } from '../../api/fmp/getTreasuryRates';

interface OptionsTableProps {
    minimumReturn: number;
    minimumDiscount: number;
    minimumBid: number;
}

export const OptionsTable: React.FC<OptionsTableProps> = ({ minimumReturn, minimumDiscount, minimumBid }) => {
    const { optionChains } = useOptionChains();
    const { riskFreeRate, setRiskFreeRate } = useRiskFreeRate();
    const [filteredOptions, setFilteredOptions] = useState<any[]>([]);

    const filters = useMemo(() => {
        const compareDate = new Date();
        return [
            filterByExpirationAfter(compareDate),
            filterByMinimumReturn(minimumReturn, riskFreeRate),
            filterByMinimumDiscount(minimumDiscount),
            filterMinimumBid(minimumBid)
        ];
    }, [minimumReturn, riskFreeRate, minimumDiscount, minimumBid]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const rates = await getTreasuryRates();
                const oneYearRate = rates.data[0].year1 / 100;
                setRiskFreeRate(oneYearRate);
            } catch (error) {
                console.error('Error fetching treasury rates:', error);
            }
        };
        fetchData();
    }, [setRiskFreeRate]);

    useEffect(() => {
        const newFilteredOptions = optionChains.filter(option =>
            filters.every(filter => filter(option))
        );
        setFilteredOptions(newFilteredOptions);
    }, [minimumReturn, minimumDiscount, minimumBid, riskFreeRate, optionChains, filters]);

    const optionsTableRows = filteredOptions.map((option) => {
        const strikePrice = option.details.strike_price.toString();
        const bid = option.last_quote.bid.toString();
        const ask = option.last_quote.ask.toString();
        const mid = option.last_quote.midpoint.toString();
        const expirationString = option.details.expiration_date;

        return (
            <OptionsTableRow
                key={option.details.ticker} // Ensure each row has a unique key
                ticker={option.underlying_asset.ticker}
                strikePrice={Number(strikePrice).toFixed(2)}
                bid={Number(bid).toFixed(2)}
                ask={Number(ask).toFixed(2)}
                mid={Number(mid).toFixed(2)}
                stock_price={option.underlying_asset.price}
                expirationString={expirationString}
            />
        );
    });

    return (
        <div className="w-full bg-white relative shadow-md overflow-hidden">
            <div className="overflow-x-auto">
                <table className="w-full text-sm text-left text-gray-500">
                    <OptionsTableHeader />
                    <tbody data-accordion="table-column">
                        {filteredOptions.length > 0 ? (
                            optionsTableRows
                        ) : (
                            <tr>
                                <td colSpan={11} className="text-center p-4">
                                    No options meet your search criteria.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};
