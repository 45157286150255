import React from 'react';

export const OptionsTableHeader: React.FC = () => {
    return (
        <>
        <thead className="bg-gray-50">
            <tr>
                <th scope="col" className="px-4 py-3">
                    <span className="sr-only">Expand/Collapse Row</span>
                </th>
                <th scope="col" className="px-4 py-3 min-w-[10rem]">
                    Ticker
                </th>
                <th scope="col" className="px-4 py-3 min-w-[6rem]">
                    Strike
                </th>
                <th scope="col" className="px-4 py-3 min-w-[6rem]">
                    Bid
                </th>
                <th scope="col" className="px-4 py-3 min-w-[6rem]">
                    Ask
                </th>
                <th scope="col" className="px-4 py-3 min-w-[6rem]">
                    Mid
                </th>
                <th scope="col" className="px-4 py-3 min-w-[12rem]">
                    Net Cost
                </th>
                <th scope="col" className="px-4 py-3 min-w-[12rem]">
                    Discount
                </th>
                <th scope="col" className="px-4 py-3 min-w-[7rem]">
                    Expiration
                </th>
                <th scope="col" className="px-4 py-3 min-w-[7rem]">
                    Return To Expiry
                </th>
            </tr>
        </thead>
        </>
    )

};
