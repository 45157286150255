import React from 'react';
import { EarningsTranscriptsList } from "./EarningsTranscriptsList";

interface EarningsTranscriptsProps {
    onSelect: (url: string, title: string) => void;
}

export const EarningsTranscripts: React.FC<EarningsTranscriptsProps> = ({ onSelect }) => {
    return (
        <div className="flex flex-col p-4 bg-white rounded-lg shadow-md">
            <div className="mb-4">
                <h4 className="text-xl font-semibold">Transcripts</h4>
                <p className="text-sm text-gray-500">Earnings Calls</p>
            </div>
            <EarningsTranscriptsList onSelect={onSelect} />
        </div>
    );
}
