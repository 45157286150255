import React, { useState, useEffect } from 'react';
import { BarChartController } from './BarChartController';
import { BarChartArea } from './BarChartArea';
import { determineUnits } from '../../../utils/formatting/determineUnits/determineUnits';
import { convertToDisplayUnits } from '../../../utils/formatting/convertToDisplayUnits/convertToDisplayUnits';
import { generateTTMLabels } from '../../../utils/dataProcessing/generateTTMlabels/generateTTMlabels';
import { useGetCompanyFinancials } from '../../../utils/hooks/useGetCompanyFinancials';
import { extractMetricData } from '../../../utils/dataProcessing/extractMetricData/extractMetricData';
import { calculateTTMArray } from '../../../utils/dataProcessing/calculateTTMArray/calculateTTMArray';
import { PeriodType } from '../../../types/PeriodType';

interface BarChartProps {
    ticker: string;
}

export const BarChart: React.FC<BarChartProps> = ({ ticker }) => {
    const [metric, setMetric] = useState('Revenue');
    const [period, setPeriod] = useState<PeriodType>('annual');
    const [yearType, setYearType] = useState('Fiscal');
    const [metricPeriodData, setMetricPeriodData] = useState<any[]>([]);
    const [timePeriods, setTimePeriods] = useState<string[]>([]);

    const { incomeData, cashFlowData, isLoading } = useGetCompanyFinancials({ ticker, period: period });

    useEffect(() => {
        if (!isLoading) {
            let metricData: any[] = [];
            let periods: string[] = [];

            if (metric === 'Cash Flow') {
                metricData = extractMetricData(cashFlowData, 'operatingCashFlow');
                periods = cashFlowData.map((data) => data.calendarYear + ' ' + data.period);
            } else if (metric === 'Revenue') {
                metricData = extractMetricData(incomeData, 'revenue');
                periods = incomeData.map((data) => data.calendarYear + ' ' + data.period);
            } else if (metric === 'Net Income') {
                metricData = extractMetricData(incomeData, 'netIncome');
                periods = incomeData.map((data) => data.calendarYear + ' ' + data.period);
            }

            if (period === 'ttm') {
                periods = generateTTMLabels(metricData);
                metricData = calculateTTMArray(metricData);
            }

            setMetricPeriodData(metricData);
            setTimePeriods(periods);
        }
    }, [incomeData, cashFlowData, period, metric, isLoading]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    const units = metricPeriodData.length > 0 ? determineUnits(metricPeriodData[0]) : 'as is';

    let seriesData: { value: number; groupId: string }[] = [];
    seriesData = metricPeriodData.map((value, index) => ({
        value: convertToDisplayUnits(value, units),
        groupId: timePeriods[index],
    })).reverse();

    return (
        <div className="bg-white w-full flex flex-col col-span-2 h-full">
            <BarChartController
                units={units}
                metric={metric}
                period={period}
                yearType={yearType}
                onMetricChange={setMetric}
                onPeriodChange={setPeriod}
                onYearTypeChange={setYearType}
            />
            <BarChartArea seriesData={seriesData} period={period} />
        </div>
    );
};
