import React from 'react';

interface DocumentViewerProps {
    url: string;
    title: string;
    onClose: () => void;
}

export const DocumentViewer: React.FC<DocumentViewerProps> = ({ url, title, onClose }) => {
    return (
        <div className="document-viewer">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold">{title}</h2>
                <button onClick={onClose} className="text-red-600">Close</button>
            </div>
            <iframe
                src={url}
                title={title}
                width="100%"
                height="600px"
                className="border-0"
            ></iframe>
        </div>
    );
};
