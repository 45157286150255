import React, { useEffect, useState } from 'react';
import { getCompanyNews, NewsArticle } from '../../../api/stocknewsapi/getCompanyNews';
import { useResearchTicker } from '../../../context/TickerContext';

interface NewsListProps {
    onSelect: (url: string, title: string) => void;
}

export const NewsList: React.FC<NewsListProps> = ({ onSelect }) => {
    const [articles, setArticles] = useState<NewsArticle[]>([]);
    const { researchTicker } = useResearchTicker();

    useEffect(() => {
        const fetchArticles = async () => {
            try {
                const newsArticles = await getCompanyNews(researchTicker);
                setArticles(newsArticles);
            } catch (error) {
                console.error('Failed to fetch articles:', error);
            }
        };

        fetchArticles();
    }, [researchTicker]);

    return (
        <ul className="space-y-2 h-64 overflow-y-auto">
            {articles.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()).map((article, index) => (
                <li
                    key={index}
                    className="flex items-center text-sm hover:bg-gray-100 px-2 py-1 cursor-pointer gap-4"
                    onClick={() => onSelect(article.news_url, article.title)}
                >
                    <span className="text-primary-700 font-semibold">{article.type}</span>
                    <span className="text-black">{article.title}</span>
                </li>
            ))}
        </ul>
    );
}
