import { useState, useEffect } from 'react';
import { API_URL } from '../../services/config/apiConfig';

interface RatioConfig {
  name: string;
  numerator: string;
  denominator: string;
  format: 'number' | 'percentage' | 'currency';
  description: string;
}

export const useGetRatioConfigs = () => {
  const [ratioConfigs, setRatioConfigs] = useState<RatioConfig[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [refresh, setRefresh] = useState<boolean>(false); // State to trigger re-fetch

  const fetchRatioConfigs = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_URL}/api/get/ratio-configs`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setRatioConfigs(data.ratios);
    } catch (error: unknown) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError('An unknown error occurred');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRatioConfigs();
  }, [refresh]); // Add refresh to the dependency array

  const refreshRatioConfigs = () => setRefresh(prev => !prev);

  return { ratioConfigs, loading, error, refreshRatioConfigs };
};
