import React, { createContext, useContext, useState, ReactNode, Dispatch, SetStateAction } from 'react';

interface IncomeStatementContextType {
    incomeStatementData: any[];
    setIncomeStatementData: Dispatch<SetStateAction<any[]>>;
    error: Error | null;
    setError: Dispatch<SetStateAction<Error | null>>;
}

const IncomeStatementContext = createContext<IncomeStatementContextType | undefined>(undefined);

export const IncomeStatementProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [incomeStatementData, setIncomeStatementData] = useState<any[]>([]);
    const [error, setError] = useState<Error | null>(null);

    return (
        <IncomeStatementContext.Provider value={{ incomeStatementData, setIncomeStatementData, error, setError }}>
            {children}
        </IncomeStatementContext.Provider>
    );
};

export const useIncomeStatementData = () => {
    const context = useContext(IncomeStatementContext);
    if (!context) {
        throw new Error('useIncomeStatementData must be used within an IncomeStatementProvider');
    }
    return context;
};
