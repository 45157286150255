import React, { ReactNode } from 'react';
import { TickerProvider } from './TickerContext';
import { CompanyProfileProvider } from './CompanyProfileContext';
import { IncomeStatementProvider } from './IncomeStatementContext';
import { CashFlowStatementProvider } from './CashFlowStatementContext';
import { BalanceSheetProvider } from './BalanceSheetContext';
import { CalculatedMetricsProvider } from './CalculatedMetricsContext';
import { OptionChainsProvider } from './OptionChainsContext';

interface ResearchTickerProviderTypes {
  children: ReactNode;
}

export const ResearchTickerProviders: React.FC<ResearchTickerProviderTypes> = ({ children }) => {
  return (
    <TickerProvider>
      <CompanyProfileProvider>
        <IncomeStatementProvider>
          <CashFlowStatementProvider>
            <BalanceSheetProvider>
              <CalculatedMetricsProvider>
                <OptionChainsProvider>
                  {children}
                </OptionChainsProvider>
              </CalculatedMetricsProvider>
            </BalanceSheetProvider>
          </CashFlowStatementProvider>
        </IncomeStatementProvider>
      </CompanyProfileProvider>
    </TickerProvider>
  );
};
