import React, { useEffect } from 'react';
import axios from 'axios';
import { useCompanyProfile } from '../../../context/CompanyProfileContext';
import { useResearchTicker } from '../../../context/TickerContext';

const FMP_API_KEY = process.env.REACT_APP_FMP_API_KEY;

interface CompanyTitleProps {
    className: string;
}

export const CompanyTitle: React.FC<CompanyTitleProps> = ({ className }) => {
    const { companyProfile, setCompanyProfile } = useCompanyProfile();
    const { researchTicker } = useResearchTicker();

    useEffect(() => {
      const fetchData = async () => {
        if (!researchTicker) return;
        try {
          const response = await axios.get(`https://financialmodelingprep.com/api/v3/profile/${researchTicker}?apikey=${FMP_API_KEY}`);
          setCompanyProfile(response.data);
        } catch (error) {
          console.error("Error fetching financial data:", error);
        }
      };
  
      fetchData();
    }, [researchTicker, setCompanyProfile]);
  
    const companyName = companyProfile && companyProfile.length > 0
        ? companyProfile[0].companyName
        : "";

    return (
      <div className="container mx-auto">
        <p className={`${className} ${!companyName ? 'opacity-0' : ''}`}>
          {companyName || "Select a company"}
        </p>
      </div>
    );
};
