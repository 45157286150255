import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import axios from 'axios';
import { WatchlistType } from '../../types/WatchlistType';
import { API_URL } from '../../services/config/apiConfig';
export interface WatchlistContextType {
  watchlist: WatchlistType;
  setWatchlist: (watchlist: WatchlistType) => void;
  isLoading: boolean;
}

const WatchlistContext = createContext<WatchlistContextType | undefined>(undefined);

export const WatchlistProvider: React.FC<{ children: ReactNode, initialWatchlist: WatchlistType }> = ({ children, initialWatchlist }) => {
  const [watchlist, setWatchlist] = useState<WatchlistType>(initialWatchlist);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchWatchlist = async () => {
      setIsLoading(true);
      if (watchlist.id) {
        try {
          const response = await axios.get(`${API_URL}/api/get/watchlist?id=${watchlist.id}`);
          const updatedWatchlist = {
            ...response.data,
            id: response.data._id,
          };
          delete updatedWatchlist._id;
          setWatchlist(updatedWatchlist);
        } catch (error) {
          console.error('Error fetching watchlist:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchWatchlist();
  }, [watchlist.id]);

  return (
    <WatchlistContext.Provider value={{ watchlist, setWatchlist, isLoading }}>
      {children}
    </WatchlistContext.Provider>
  );
};

export const useWatchlist = () => {
  const context = useContext(WatchlistContext);
  if (!context) {
    throw new Error('useWatchlist must be used within a WatchlistProvider');
  }
  return context;
};
