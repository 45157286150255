import React, { createContext, useContext, useState, ReactNode, Dispatch, SetStateAction } from 'react';

interface BalanceSheetContextType {
    balanceSheetData: any[];
    setBalanceSheetData: Dispatch<SetStateAction<any[]>>;
    error: Error | null;
    setError: Dispatch<SetStateAction<Error | null>>;
}

const BalanceSheetContext = createContext<BalanceSheetContextType | undefined>(undefined);

export const BalanceSheetProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [balanceSheetData, setBalanceSheetData] = useState<any[]>([]);
    const [error, setError] = useState<Error | null>(null);

    return (
        <BalanceSheetContext.Provider value={{ balanceSheetData, setBalanceSheetData, error, setError }}>
            {children}
        </BalanceSheetContext.Provider>
    );
};

export const useBalanceSheetData = () => {
    const context = useContext(BalanceSheetContext);
    if (!context) {
        throw new Error('useBalanceSheetData must be used within a BalanceSheetProvider');
    }
    return context;
};
