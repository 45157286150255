import React, { useEffect, useRef, useMemo } from 'react';
import * as echarts from 'echarts';
import { calculateYoYChange } from '../../../utils/dataProcessing/calculateYoYChange/calculateYoYChange';

interface DataItem {
  value: number;
  groupId: string;
}

interface BarChartAreaProps {
  seriesData: DataItem[];
  period: string;
}

export const BarChartArea: React.FC<BarChartAreaProps> = ({ seriesData, period }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const myChart = useRef<echarts.ECharts | null>(null);

  // Determine the shift based on the period type
  const shift = period === 'Quarterly' ? 4 : 1;
  const isQuarterly = period === 'Quarterly';
  const reportingOrder = 'asc'

  const option = useMemo((): echarts.EChartsOption => {
    // Calculate YoY change series
    const values = seriesData.map((data) => data.value);
    const yoyChange: number[] = calculateYoYChange(values, isQuarterly, reportingOrder);

    // Align YoY change data with the correct groupId
    const yoyChangeData: DataItem[] = yoyChange.map((value, index) => {
      return {
        value,
        groupId: seriesData[index + shift]?.groupId || '', // Ensure groupId exists
      };
    });

    // Ensure the first few periods of YoY change data are properly initialized (they will not have previous data to compare)
    const paddedYoYChangeData: DataItem[] = [
      ...new Array(shift).fill({ value: NaN, groupId: '' }),
      ...yoyChangeData,
    ];

    return {
      grid: {
        left: '3%',
        right: '4%',
        bottom: '20%',
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        data: seriesData.map((data) => data.groupId),
      },
      yAxis: [
        {
          type: 'value',
          axisLabel: {
            formatter: (value: number) => `$${value}`,
          },
        },
        {
          type: 'value',
          axisLabel: {
            formatter: (value: number) => `${(value * 100).toFixed(2)}%`,
          },
          splitLine: {
            show: false, // Hide gridlines for YoY axis
          },
        },
      ],
      legend: {
        data: ['YoY Change'],
        top: '4%', // Position at the top with a 10% margin
        left: 'center', // Center horizontally
        orient: 'horizontal', // Horizontal layout
      },
      dataGroupId: '',
      animationDurationUpdate: 500,
      series: [
        {
          type: 'bar',
          id: 'sales',
          name: 'Sales',
          itemStyle: {
            color: '#15803d',
          },
          data: seriesData,
          universalTransition: {
            enabled: true,
            divideShape: 'clone',
          },
        },
        {
          type: 'line',
          id: 'yoyChange',
          name: 'YoY Change',
          yAxisIndex: 1,
          itemStyle: {
            color: '#818cf8',
          },
          data: paddedYoYChangeData, // Ensure alignment with the correct year
          smooth: true,
        },
      ],
      tooltip: {
        trigger: 'axis',
        formatter: (params: any) => {
          const salesParam = params.find((param: any) => param.seriesId === 'sales');
          const yoyParam = params.find((param: any) => param.seriesId === 'yoyChange');
          return `
            ${salesParam.name}: $${salesParam.value.toLocaleString()}<br/>
            YoY Change: ${(yoyParam.value * 100).toFixed(2)}%
          `;
        },
      },
      dataZoom: [
        {
          type: 'slider',
          start: 100 * (1 - 8 / seriesData.length), // Scroll to the rightmost part
          end: 100, // Show the last 8 bars initially
          bottom: 35, // Position the scroll bar at the bottom of the chart container
          showDataShadow: false,
          borderColor: '#C5CAE9',
          handleSize: '100%',
          handleStyle: {
            color: '#C5CAE9', // Tailwind CSS indigo-100 color
          },
          fillerColor: '#C5CAE9', // Tailwind CSS indigo-100 color
          textStyle: {
            color: 'transparent',
          }
        },
        {
          type: 'inside',
          start: 100 * (1 - 8 / seriesData.length),
          end: 100,
        },
      ],
    };
  }, [seriesData, shift, isQuarterly, reportingOrder]);

  useEffect(() => {
    if (chartRef.current) {
      myChart.current = echarts.init(chartRef.current);

      myChart.current.setOption(option);

      return () => {
        myChart.current?.dispose();
      };
    }
  }, [option]);

  return <div className='bg-white shadow-md h-full' ref={chartRef} style={{ width: '100%', height: '400px' }} />;
};
