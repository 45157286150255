import axios from 'axios';

const FMP_API_KEY = process.env.REACT_APP_FMP_API_KEY;

export const getQuarterlyIncomeStatement = async (ticker: string) => {
  try {
    const response = await axios.get(`https://financialmodelingprep.com/api/v3/income-statement/${ticker}?period=quarter&apikey=${FMP_API_KEY}`);
    return response;
  } catch (error) {
    console.error('Error fetching company profile:', error);
    throw error; // Rethrow the error so it can be handled by the caller
  }
};
