import React, { useState, ChangeEvent, KeyboardEvent, useEffect, useRef } from 'react';
import { FixedSizeList as List } from 'react-window';
import { SearchHits } from './SearchHits';
import { getAllTickers } from '../../../api/fmp/getAllTickers';
import { useNavigate } from 'react-router-dom';

interface TickerSearchProps {
  setTicker: (ticker: string) => void;
}

export const TickerSearch: React.FC<TickerSearchProps> = ({ setTicker }) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [tickers, setTickers] = useState<{ ticker: string, companyName: string }[]>([]);
  const [filteredTickers, setFilteredTickers] = useState<{ ticker: string, companyName: string }[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<List>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTickers = async () => {
      try {
        const data = await getAllTickers();
        setTickers(data.map((item: any) => ({
          ticker: item.symbol || '',
          companyName: item.name || ''
        })));
      } catch (error) {
        console.error('Error fetching tickers:', error);
      }
    };

    fetchTickers();
  }, []);

  useEffect(() => {
    if (inputValue) {
      const lowercasedFilter = inputValue.toLowerCase();
      const exactMatch = tickers.filter(({ ticker }) => ticker && ticker.toLowerCase() === lowercasedFilter);
      const startsWithMatch = tickers.filter(({ ticker, companyName }) => 
        (ticker && ticker.toLowerCase().startsWith(lowercasedFilter)) || 
        (companyName && companyName.toLowerCase().startsWith(lowercasedFilter))
      ).filter(({ ticker }) => ticker.toLowerCase() !== lowercasedFilter);
      const otherMatches = tickers.filter(({ ticker, companyName }) =>
        (ticker && ticker.toLowerCase().includes(lowercasedFilter)) || 
        (companyName && companyName.toLowerCase().includes(lowercasedFilter))
      ).filter(({ ticker, companyName }) => 
        ticker.toLowerCase() !== lowercasedFilter && 
        !ticker.toLowerCase().startsWith(lowercasedFilter) && 
        !companyName.toLowerCase().startsWith(lowercasedFilter)
      );
      setFilteredTickers([...exactMatch, ...startsWithMatch, ...otherMatches]);
      setIsDropdownOpen(true);
    } else {
      setFilteredTickers([]);
      setIsDropdownOpen(false);
    }
  }, [inputValue, tickers]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    setHighlightedIndex(-1); // Reset highlight when input changes
  };

  const handleSearch = (ticker: string) => {
    setTicker(ticker);
    setInputValue(ticker);
    setFilteredTickers([]);
    setIsDropdownOpen(false);
    navigate('/overview'); // Navigate to Overview page
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (highlightedIndex >= 0 && highlightedIndex < filteredTickers.length) {
        handleSearch(filteredTickers[highlightedIndex].ticker);
      } else {
        handleSearch(inputValue);
      }
    } else if (e.key === 'ArrowDown') {
      setHighlightedIndex((prevIndex) => {
        const newIndex = (prevIndex + 1) % filteredTickers.length;
        listRef.current?.scrollToItem(newIndex);
        return newIndex;
      });
    } else if (e.key === 'ArrowUp') {
      setHighlightedIndex((prevIndex) => {
        const newIndex = (prevIndex - 1 + filteredTickers.length) % filteredTickers.length;
        listRef.current?.scrollToItem(newIndex);
        return newIndex;
      });
    }
  };

  const handleItemClick = (ticker: string) => {
    handleSearch(ticker);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const Row = ({ index, style }: { index: number; style: React.CSSProperties }) => (
    <div
      style={{ ...style, backgroundColor: highlightedIndex === index ? '#f0f0f0' : 'transparent' }}
      key={filteredTickers[index].ticker}
      onClick={() => handleItemClick(filteredTickers[index].ticker)}
    >
      <SearchHits
        ticker={filteredTickers[index].ticker}
        companyName={filteredTickers[index].companyName}
        onClick={() => handleItemClick(filteredTickers[index].ticker)}
      />
    </div>
  );

  return (
    <div className="mb-4 relative" ref={wrapperRef}>
      <div className="relative w-full">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
          </svg>
        </div>
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyPress}
          placeholder="Search ticker..."
          className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500"
        />
      </div>
      {isDropdownOpen && filteredTickers.length > 0 && (
        <div className="absolute left-0 mt-1 w-96 bg-white border border-gray-300 rounded-md max-h-48 overflow-y-auto overflow-x-hidden shadow-lg z-30">
          <List
            height={192} // Adjust based on your max height
            itemCount={filteredTickers.length}
            itemSize={35} // Adjust based on your item height
            width={384} // Adjust based on your width
            ref={listRef}
          >
            {Row}
          </List>
        </div>
      )}
    </div>
  );
};
