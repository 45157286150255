import { useState } from 'react';
import { MetricFieldList } from '../../types/MetricFieldList';
import { FieldItem } from '../Common/FieldItem/FieldItem';

interface MetricSelectorProps {
    onChange: (metric: string) => void;
}

export const MetricSelector: React.FC<MetricSelectorProps> = ({ onChange }) => {
    const [selectedMetric, setSelectedMetric] = useState<string>("");
    const [metricDescription, setMetricDescription] = useState<string>("");

    const handleMetricSelect = (metric: string, index: number) => {
        setSelectedMetric(metric);
        setMetricDescription(MetricFieldList[index].description);
        onChange(metric); // Call onChange with the selected metric
    };

    return (
        <div className="grid grid-cols-2 gap-6">
            <div className="flex flex-col">
                <p className="mb-2 font-semibold leading-none text-gray-900">Select a Metric</p>
                <dl className="p-4 border border-gray-200 rounded-lg bg-gray-50 mb-2 h-64 overflow-y-auto">
                    {MetricFieldList.map((field, index) => (
                        <FieldItem key={field.key} index={index} field={field.key} handleFieldSelect={() => handleMetricSelect(field.key, index)} />
                    ))}
                </dl>
            </div>
            <dl>
                <dt className="mb-4 font-semibold leading-none text-gray-900">{selectedMetric}</dt>
                <dd className="font-light text-gray-600">
                    {metricDescription}
                </dd>
            </dl>
        </div>
    );
};
