import React from 'react';

interface SearchHitsProps {
  ticker: string;
  companyName: string;
  onClick: () => void;
}

export const SearchHits: React.FC<SearchHitsProps> = ({ ticker, companyName, onClick }) => {
  return (
    <li
      className="px-4 py-2 cursor-pointer hover:bg-gray-100 flex gap-4 items-center"
      onClick={onClick}
    >
      <span className="w-16 text-primary-600 font-bold truncate">{ticker}</span>
      <span className="flex-1 truncate">{companyName}</span>
    </li>
  );
};
