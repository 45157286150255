import React, { createContext, useContext, useState, ReactNode, Dispatch, SetStateAction } from 'react';

interface CashFlowStatementContextType {
    cashFlowStatementData: any[];
    setCashFlowStatementData: Dispatch<SetStateAction<any[]>>;
    error: Error | null;
    setError: Dispatch<SetStateAction<Error | null>>;
}

const CashFlowStatementContext = createContext<CashFlowStatementContextType | undefined>(undefined);

export const CashFlowStatementProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [cashFlowStatementData, setCashFlowStatementData] = useState<any[]>([]);
    const [error, setError] = useState<Error | null>(null);

    return (
        <CashFlowStatementContext.Provider value={{ cashFlowStatementData, setCashFlowStatementData, error, setError }}>
            {children}
        </CashFlowStatementContext.Provider>
    );
};

export const useCashFlowStatementData = () => {
    const context = useContext(CashFlowStatementContext);
    if (!context) {
        throw new Error('useCashFlowStatementData must be used within a CashFlowStatementProvider');
    }
    return context;
};
