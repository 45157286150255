import React from 'react';

interface PeriodRowProps {
    row_title: string;
    periods: string[];
}

const PeriodRow: React.FC<PeriodRowProps> = ({ row_title, periods }) => {
    return <>
        <tr className="bg-white">
            <th scope="col" className="sticky top-0 sticky left-0 z-10 w-80 text-left px-4 py-3 bg-gray-50">
                {row_title}
            </th>
            {periods.map((period, index) => (
                <th key={index} scope="col" className="sticky top-0 w-40 px-4 py-3 bg-gray-50">
                    {period}
                </th>
            ))}
         </tr>
    </>
};

export default PeriodRow;