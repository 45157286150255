import { FinancialDataTypes } from "../../../types/FinancialDataTypes";
import { calculateLastTTM } from "../../dataProcessing/calculateLastTTM/calculateLastTTM";
import { MetricFieldList } from "../../../types/MetricFieldList";
import { CalculatedMetricTypes } from "../../../types/CalculatedMetricTypes";

// Extract keys from MetricFieldList
type MetricKeys = typeof MetricFieldList[number]['key'];

export const calculateRatio = (
  data: FinancialDataTypes,
  numerator: MetricKeys,
  denominator: MetricKeys,
  format: 'number' | 'percentage' | 'currency',
  netCost?: number
): { value: number, formattedValue: string } => {
  // Ensure data is valid
  if (!data.incomeData.length && !data.cashFlowData.length && !data.balanceSheetData.length && !data.companyProfile.length) {
    throw new Error('One of the financial statements was not available when attempting to calculate the ratio.');
  }

  // List of metrics that need to be inverted
  const metricsToInvert = ['Dividends Paid'];

  // Helper function to get the value based on the field and source
  const getValue = (field: MetricKeys): number => {
    if (field === 'Market Cap' && netCost !== undefined) {
      const sharesOutstanding = data.incomeData[0]?.weightedAverageShsOut;
      if (sharesOutstanding === undefined || typeof sharesOutstanding !== 'number') {
        throw new Error('weightedAverageShsOut is not available or is not a number in incomeData');
      }
      return sharesOutstanding * netCost;
    }

    if (field === 'Enterprise Value' && netCost !== undefined) {
      const sharesOutstanding = data.incomeData[0]?.weightedAverageShsOut;
      if (sharesOutstanding === undefined || typeof sharesOutstanding !== 'number') {
        throw new Error('weightedAverageShsOut is not available or is not a number in incomeData');
      }
      const mktCap = sharesOutstanding * netCost;
      const cash = data.balanceSheetData[0]?.cashAndShortTermInvestments + data.balanceSheetData[0]?.longTermInvestments;
      const debt = data.balanceSheetData[0]?.totalDebt;
      if (cash === undefined || debt === undefined || typeof cash !== 'number' || typeof debt !== 'number') {
        throw new Error('cashAndShortTermInvestments, longTermInvestments, or totalDebt is not available or is not a number in balanceSheetData');
      }
      return mktCap - cash + debt;
    }

    const metric = MetricFieldList.find(item => item.key === field);
    if (!metric) throw new Error(`Metric ${field} not found`);

    const { source, field: metricField } = metric;

    const extractValue = <T>(obj: T, key: keyof T): number => {
      const value = obj[key];
      if (typeof value !== 'number') {
        throw new Error(`Field ${String(key)} is not a number`);
      }
      return value;
    };

    let value;
    switch (source) {
      case 'income':
        const incomeValues = data.incomeData.map(entry => extractValue(entry, metricField as keyof typeof data.incomeData[0]));
        value = calculateLastTTM(incomeValues);
        break;
      case 'cashFlow':
        const cashFlowValues = data.cashFlowData.map(entry => extractValue(entry, metricField as keyof typeof data.cashFlowData[0]));
        value = calculateLastTTM(cashFlowValues);
        break;
      case 'balanceSheet':
        value = extractValue(data.balanceSheetData[0], metricField as keyof typeof data.balanceSheetData[0]);
        break;
      case 'profile':
        value = extractValue(data.companyProfile[0], metricField as keyof typeof data.companyProfile[0]);
        break;
      case 'calculated':
        const calculatedMetric = data.calculatedMetrics[metricField as keyof CalculatedMetricTypes];
        if (typeof calculatedMetric !== 'number') {
          throw new Error(`Calculated metric ${String(metricField)} is not a number`);
        }
        value = calculatedMetric;
        break;
      default:
        throw new Error(`Invalid source ${source} for field ${field}`);
    }

    // Invert the value if the metric is in the metricsToInvert list
    if (metricsToInvert.includes(field)) {
      value = -value;
    }

    return value;
  };

  // Get numerator and denominator values
  const numeratorValue = getValue(numerator);
  const denominatorValue = getValue(denominator);

  // Calculate the ratio
  const value = denominatorValue !== 0 ? numeratorValue / denominatorValue : 0;

  // Format the value
  let formattedValue: string;
  switch (format) {
    case 'number':
      formattedValue = value.toFixed(2);
      break;
    case 'percentage':
      formattedValue = (value * 100).toFixed(2) + '%';
      break;
    case 'currency':
      formattedValue = `$${value.toFixed(2)}`;
      break;
    default:
      throw new Error(`Invalid format ${format}`);
  }

  return { value, formattedValue };
};
