import { CalculatedMetricTypes } from "../../types/CalculatedMetricTypes";
import { FMPBalanceSheetStatementType } from "../../types/FMPBalanceSheetStatement";
import { FMPCashFlowStatementType } from "../../types/FMPCashFlowStatement";
import { FMPIncomeStatementType } from "../../types/FMPIncomeStatement";
import { FMPCompanyProfile } from "../../types/FMPCompanyProfile";

export const calculateMetrics = (
    incomeData: FMPIncomeStatementType[],
    cashFlowData: FMPCashFlowStatementType[],
    balanceSheetData: FMPBalanceSheetStatementType[],
    companyProfile: FMPCompanyProfile[],
    netCost?: number
): CalculatedMetricTypes => {
    let mktCap: number | undefined;

    if (netCost === undefined) {
        mktCap = companyProfile?.length ? companyProfile[0].mktCap : undefined;
    } else {
        const sharesOutstanding = incomeData?.length ? incomeData[0].weightedAverageShsOut : undefined;
        if (sharesOutstanding !== undefined) {
            mktCap = netCost * sharesOutstanding;
        } else {
            mktCap = undefined;
        }
    }

    const cash = balanceSheetData?.length
        ? (balanceSheetData[0].cashAndShortTermInvestments ?? 0) + (balanceSheetData[0].longTermInvestments ?? 0)
        : 0;

    const debt = balanceSheetData?.length ? balanceSheetData[0].totalDebt ?? 0 : 0;

    const enterpriseValue = (mktCap ?? 0) - cash + debt;

    return { enterpriseValue };
};
