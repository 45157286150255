import { useEffect } from 'react';
import { useCompanyProfile } from '../../context/CompanyProfileContext';
import { useIncomeStatementData } from '../../context/IncomeStatementContext';
import { useCashFlowStatementData } from '../../context/CashFlowStatementContext';
import { useBalanceSheetData } from '../../context/BalanceSheetContext';
import { useResearchTicker } from '../../context/TickerContext';
import { useOptionChains } from '../../context/OptionChainsContext';
import { getQuarterlyIncomeStatement } from '../../api/fmp/getQuarterlyIncomeStatement';
import { getQuarterlyCashFlowStatement } from '../../api/fmp/getQuarterlyCashFlowStatement';
import { getQuarterlyBalanceSheet } from '../../api/fmp/getQuarterlyBalanceSheet';
import { getCompanyProfile } from '../../api/fmp/getCompanyProfile';
import { getOptionsSnapshot } from '../../api/polygon/getOptionChains';

export const useSetResearchTickerData = () => {
  const { researchTicker } = useResearchTicker();
  const { setCompanyProfile, setError: setCompanyProfileError } = useCompanyProfile();
  const { setIncomeStatementData, setError: setIncomeStatementError } = useIncomeStatementData();
  const { setCashFlowStatementData, setError: setCashFlowStatementError } = useCashFlowStatementData();
  const { setBalanceSheetData, setError: setBalanceSheetError } = useBalanceSheetData();
  const { setOptionChains, setError: setOptionChainsError, setIsLoading: setOptionChainsLoading } = useOptionChains();

  useEffect(() => {
    if (!researchTicker) return;

    const updateFinancials = async () => {
      try {
        // Fetch company profile
        const companyProfileResponse = await getCompanyProfile(researchTicker);
        setCompanyProfile(companyProfileResponse.data);

        // Fetch balance sheet data
        const balanceSheetResponse = await getQuarterlyBalanceSheet(researchTicker);
        setBalanceSheetData(balanceSheetResponse.data);

        // Fetch income statement data
        const incomeStatementResponse = await getQuarterlyIncomeStatement(researchTicker);
        setIncomeStatementData(incomeStatementResponse.data);

        // Fetch cash flow statement data
        const cashFlowStatementResponse = await getQuarterlyCashFlowStatement(researchTicker);
        setCashFlowStatementData(cashFlowStatementResponse.data);
      } catch (error: unknown) {
        if (error instanceof Error) {
          setCompanyProfileError(error);
          setIncomeStatementError(error);
          setCashFlowStatementError(error);
          setBalanceSheetError(error);
        } else {
          const unknownError = new Error('An unknown error occurred');
          setCompanyProfileError(unknownError);
          setIncomeStatementError(unknownError);
          setCashFlowStatementError(unknownError);
          setBalanceSheetError(unknownError);
        }
      }
    };

    const updateOptions = async () => {
      setOptionChainsLoading(true);
      try {
        const optionsSnapshotResponse = await getOptionsSnapshot(researchTicker);
        setOptionChains(optionsSnapshotResponse.results);
      } catch (error: unknown) {
        if (error instanceof Error) {
          setOptionChainsError(error);
        } else {
          const unknownError = new Error('An unknown error occurred');
          setOptionChainsError(unknownError);
        }
      } finally {
        setOptionChainsLoading(false);
      }
    };

    updateFinancials();
    updateOptions();
  }, [
    researchTicker,
    setCompanyProfile,
    setCompanyProfileError,
    setIncomeStatementData,
    setIncomeStatementError,
    setCashFlowStatementData,
    setCashFlowStatementError,
    setBalanceSheetData,
    setBalanceSheetError,
    setOptionChains,
    setOptionChainsError,
    setOptionChainsLoading,
  ]);
};
