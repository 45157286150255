import React from 'react';
import { SidebarLink } from './SidebarLink';
import { TickerSearch } from '../Common/Search/TickerSearch';
import { useResearchTicker } from '../../context/TickerContext';
import { useSetResearchTickerData } from '../../utils/hooks/useSetResearchTickerData';
import { DocumentIcon, FinancialIcon, NewsIcon, NotebookIcon, OptionsIcon, OverviewIcon, SandboxIcon, ScreenerIcon, StreamsIcon, WatchlistIcon } from '../Common/Icons/Icons';

export const Sidebar: React.FC = () => {
  const { researchTicker, setResearchTicker } = useResearchTicker();

  // Use the custom hook to update company financials whenever researchTicker changes
  useSetResearchTickerData();

  return (
    <div className="fixed top-12 w-52 h-screen bg-gray-50 border-r border-gray-200 p-4 pt-6 ml-4 z-10">
      <div>
        <h1 className="text-sm font-bold text-gray-800 mb-4">Discover</h1>
        <ul className="space-y-1">
          <SidebarLink to="/watchlist" icon={<WatchlistIcon className="h-4 w-4" />}>Watchlist</SidebarLink>
          <SidebarLink to="/screener" icon={<ScreenerIcon className="h-4 w-4" />}>Screener</SidebarLink>
          <SidebarLink to="/streams" icon={<StreamsIcon className="h-4 w-4" />}>Streams</SidebarLink>
          <SidebarLink to="/sandbox" icon={<SandboxIcon className="h-4 w-4" />}>Sandbox</SidebarLink>
        </ul>
      </div>
      <hr className="my-4 border-gray-300" />
      <div>
        <h1 className="text-sm font-bold text-gray-800 mb-4">Research</h1>
        <TickerSearch setTicker={setResearchTicker} />
        {researchTicker && (
          <ul className="mt-4 space-y-1">
            <SidebarLink to="/overview" icon={<OverviewIcon className="h-4 w-4" />}>Overview</SidebarLink>
            <SidebarLink to="/notebook" icon={<NotebookIcon className="h-4 w-4" />}>Notebook</SidebarLink>
            <SidebarLink to="/financials" icon={<FinancialIcon className="h-4 w-4" />}>Financials</SidebarLink>
            <SidebarLink to="/documents" icon={<DocumentIcon className="h-4 w-4" />}>Documents</SidebarLink>
            <SidebarLink to="/company-news" icon={<NewsIcon className="h-4 w-4" />}>News</SidebarLink>
            <SidebarLink to="/options" icon={<OptionsIcon className="h-4 w-4" />}>Options</SidebarLink>
          </ul>
        )}
      </div>
    </div>
  );
};
