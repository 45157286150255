import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { MainContainer } from '../components/Containers/MainContainer';

export const SandBoxPage: React.FC = () => {
  const { user } = useAuth0();

  return (
    <MainContainer>
      <h1 className="text-xl text-primary-600 font-semibold">User Login Information (Deployment Test)</h1>
      <pre className="mt-4 p-4 bg-gray-100 rounded-md">
        {JSON.stringify(user, null, 2)}
      </pre>
    </MainContainer>
  );
};
