import React from 'react';
import { MainContainer } from '../components/Containers/MainContainer';
import { BlockNoteEditor } from "@blocknote/core";
import { BlockNoteView, useBlockNote } from "@blocknote/react";
import { CompanyTitle } from '../components/Common/CompanyTitle/CompanyTitle';

export const NotebookPage: React.FC = () => {
    const editor: BlockNoteEditor = useBlockNote();

    return (
        <MainContainer>
            <CompanyTitle className='text-2xl font-bold text-primary-600 mb-4' />
            <h1 className="text-xl font-bold mb-12">Notebook</h1>
            <BlockNoteView editor={editor} />
        </MainContainer>
    );
};

export default NotebookPage;
