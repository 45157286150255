import { WatchlistRowType } from '../../types/WatchlistRowType';

interface RatioData {
    value: number;
    format: 'number' | 'percentage' | 'currency';
}

export const mapWatchlistData = (
    companyProfiles: any[],
    sharesOutstanding: any[],
    fullQuote: any[],
    balanceSheet: any[],
    ratios: { [ticker: string]: { [ratioName: string]: RatioData } },
    selectedRatio: string
): WatchlistRowType[] => {
    return companyProfiles.map((profile, index) => {
        const shareData = sharesOutstanding[index]?.data?.[0];
        const profileData = profile?.data?.[0];
        const fullQuoteData = fullQuote[index]?.data?.[0];
        const balanceSheetData = balanceSheet[index]?.data?.[0];

        if (!profileData || !fullQuoteData || !shareData || !balanceSheetData) {
            return null;
        }

        return {
            ticker: profileData.symbol,
            company: profileData.companyName,
            stock_price: fullQuoteData.price,
            percent_change: Number((((fullQuoteData.price - fullQuoteData.previousClose) / fullQuoteData.previousClose) * 100).toFixed(2)),
            sharesOutstanding: shareData.outstandingShares,
            marketCap: profileData.mktCap / 1000000000,
            enterpriseValue: (profileData.mktCap - (balanceSheetData.cashAndShortTermInvestments + balanceSheetData.longTermInvestments) + (balanceSheetData.shortTermDebt + balanceSheetData.longTermDebt + balanceSheetData.capitalLeaseObligations)) / 1000000000,
            cash: (balanceSheetData.cashAndShortTermInvestments + balanceSheetData.longTermInvestments) / 1000000000,
            debt: (balanceSheetData.shortTermDebt + balanceSheetData.longTermDebt + balanceSheetData.capitalLeaseObligations) / 1000000000,
            ratioValue: ratios[profileData.symbol]?.[selectedRatio]?.value || 0 // Access the correct ratio value
        };
    }).filter((row): row is WatchlistRowType => row !== null);
};
