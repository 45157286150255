import React from 'react';
import { PercentInputBox } from '../Common/InputBox/PercentInputBox';
import { CurrencyInputBox } from '../Common/InputBox/CurrencyInputBox';
import { cleanFloat } from '../../utils/formatting/cleanFloat/cleanFloat';

interface OptionsTableControlsProps {
    setMinimumReturn: (value: number) => void;
    setMinimumDiscount: (value: number) => void;
    setMinimumBid: (value: number) => void;
}

export const OptionsTableControls: React.FC<OptionsTableControlsProps> = ({setMinimumReturn, setMinimumDiscount, setMinimumBid }) => {

    const handleMinimumReturn = (e: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;
        const cleanedMinimumReturn = Number(cleanFloat(target.value)) / 100;
        console.log(cleanedMinimumReturn);
        setMinimumReturn(cleanedMinimumReturn);
    }

    const handleMinimumDiscount = (e: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;
        const cleanedMinimumDiscount = Number(cleanFloat(target.value)) / 100;
        console.log(cleanedMinimumDiscount);
        setMinimumDiscount(cleanedMinimumDiscount);
    }

    const handleMinimumBid = (e: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;
        const cleanedMinimumBid = Number(cleanFloat(target.value));
        setMinimumBid(cleanedMinimumBid);
        console.log(cleanedMinimumBid);
    };


    return (
    <>
        <section className="w-full flex items-center mb-8">
        <div className="w-full mx-auto">
            <div className="relative bg-white">
                <div className="font-medium text-gray-500 mr-8 mb-2">
                    Filters
                </div>
                <div
                    className="flex flex-col-reverse items-start md:flex-row md:items-center md:space-x-4">
                    <div className="grid grid-cols-1 md:grid-cols-4 lg:w-2/3 md:gap-4">
                        <PercentInputBox label="Minimum Return" placeholder="Minimum Return" onChange={handleMinimumReturn}/>
                        <PercentInputBox label="Minimum Discount" placeholder="Minimum Discount" onChange={handleMinimumDiscount}/>
                        <CurrencyInputBox label="Minimum Bid" onChange={handleMinimumBid}/>
                    </div>
                </div>
            </div>
        </div>
        </section>
    </>
    )
}
