import React from 'react';

interface CreateNewWatchlistProps {
    setIsDropdownOpen: (isOpen: boolean) => void;
    handleOpenModal: () => void;
}

export const CreateNewWatchlist: React.FC<CreateNewWatchlistProps> = ({ setIsDropdownOpen, handleOpenModal }) => {
    return (
        <li
            className="px-4 py-2 text-primary-600 hover:bg-gray-100 cursor-pointer flex justify-between items-center"
            onClick={handleOpenModal}
        >
            + Create New
        </li>
    );
};
