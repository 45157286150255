import { useEffect, useState } from 'react';
import { getQuarterlyIncomeStatement } from '../../api/fmp/getQuarterlyIncomeStatement';
import { getAnnualIncomeStatement } from '../../api/fmp/getAnnualIncomeStatement';
import { getQuarterlyCashFlowStatement } from '../../api/fmp/getQuarterlyCashFlowStatement';
import { getAnnualCashFlowStatement } from '../../api/fmp/getAnnualCashFlowStatement';
import { getQuarterlyBalanceSheet } from '../../api/fmp/getQuarterlyBalanceSheet';
import { getCompanyProfile } from '../../api/fmp/getCompanyProfile';
import { FMPIncomeStatementType } from '../../types/FMPIncomeStatement';
import { FMPCashFlowStatementType } from '../../types/FMPCashFlowStatement';
import { FMPBalanceSheetStatementType } from '../../types/FMPBalanceSheetStatement';
import { FMPCompanyProfile } from '../../types/FMPCompanyProfile';

interface UseGetCompanyFinancialsProps {
    ticker: string;
    period: 'quarterly' | 'annual' | 'ttm';
};

export const useGetCompanyFinancials = ({ ticker, period }: UseGetCompanyFinancialsProps) => {
    const [incomeData, setIncomeData] = useState<FMPIncomeStatementType[]>([]);
    const [cashFlowData, setCashFlowData] = useState<FMPCashFlowStatementType[]>([]);
    const [balanceSheetData, setBalanceSheetData ] = useState<FMPBalanceSheetStatementType[]>([]);
    const [companyProfile, setCompanyProfile] = useState<FMPCompanyProfile[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const companyProfileResponse = await getCompanyProfile(ticker);
                setCompanyProfile(companyProfileResponse.data);
                const balanceSheetResponse = await getQuarterlyBalanceSheet(ticker);
                setBalanceSheetData(balanceSheetResponse.data);
                    if (period === 'quarterly' || period === 'ttm') {
                        const cashFlowResponse = await getQuarterlyCashFlowStatement(ticker);
                        setCashFlowData(cashFlowResponse.data);
                        const incomeResponse = await getQuarterlyIncomeStatement(ticker);
                        setIncomeData(incomeResponse.data);
                    } else if (period === 'annual') {
                        const cashFlowResponse = await getAnnualCashFlowStatement(ticker);
                        setCashFlowData(cashFlowResponse.data);
                        const incomeResponse = await getAnnualIncomeStatement(ticker);
                        setIncomeData(incomeResponse.data);
                    }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [ticker, period]);

    return { incomeData, cashFlowData, balanceSheetData, companyProfile, isLoading };
};
