import React from 'react';
import { OtherFilingsList } from "./OtherFilingsList";

interface OtherFilingsProps {
    onSelect: (url: string, title: string) => void;
}

export const OtherFilings: React.FC<OtherFilingsProps> = ({ onSelect }) => {
    return (
        <div className="flex flex-col p-4 bg-white rounded-lg shadow-md">
            <div className="mb-4">
                <h4 className="text-xl font-semibold">Other Filings</h4>
                <p className="text-sm text-gray-500">Ownership, Annual Meetings, etc.</p>
            </div>
            <OtherFilingsList onSelect={onSelect} />
        </div>
    );
}
