import React from 'react';
import { AddTickerModal } from './AddTickerModal';

interface NewWatchlistModalProps {
    onClose: () => void;
}

export const NewWatchlistModal: React.FC<NewWatchlistModalProps> = ({ onClose }) => {
    return (
        <>
            <h2 className="text-2xl font-semibold text-gray-800">Create New Watchlist</h2>
            <AddTickerModal onClose={onClose} />
        </>
    );
};
