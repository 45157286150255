import React, { useEffect, useState, useMemo } from 'react';
import { shortNumberFormat } from '../../../utils/formatting/shortNumberFormat/shortNumberFormat';
import { MetricModal } from '../../MetricModal/MetricModal';
import { selectRatio } from '../../../utils/math/ratios/selectRatio';
import { useGetCompanyFinancials } from '../../../utils/hooks/useGetCompanyFinancials';
import { useGetRatioConfigs } from '../../../utils/hooks/useGetRatioConfigs';
import { FinancialDataTypes } from '../../../types/FinancialDataTypes';
import { calculateMetrics } from '../../../utils/math/calculateMetrics';

interface MetricCardProps {
  ticker: string;
  className?: string;
}

export const MetricsCard: React.FC<MetricCardProps> = ({ className, ticker }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRatio, setSelectedRatio] = useState<string>('Earnings Yield');
  const [ratioValue, setRatioValue] = useState<string | number | undefined>(undefined);

  const { incomeData, cashFlowData, balanceSheetData, companyProfile, isLoading } = useGetCompanyFinancials({ ticker, period: 'quarterly' });
  const calculatedMetrics = calculateMetrics(incomeData, cashFlowData, balanceSheetData, companyProfile);

  const { ratioConfigs, loading: isLoadingRatios, error } = useGetRatioConfigs();

  const financialData: FinancialDataTypes = useMemo(() => ({
    incomeData,
    cashFlowData,
    balanceSheetData,
    companyProfile,
    calculatedMetrics,
    isLoading: isLoading
  }), [incomeData, cashFlowData, balanceSheetData, companyProfile, calculatedMetrics, isLoading]);

  useEffect(() => {
    if (selectedRatio && ratioConfigs.length > 0) {
      try {
        const result = selectRatio({ ratioConfigs, financialData, ratioName: selectedRatio });
        console.log('Result from selectRatio:', result);

        if (result) {
          setRatioValue(result.formattedValue);
        } else {
          setRatioValue('N/A');
        }
      } catch (error) {
        console.error('Error calculating ratio:', error);
        setRatioValue('N/A');
      }
    }
  }, [selectedRatio, ratioConfigs, financialData]);

  if (isLoading || isLoadingRatios) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  const companyName = companyProfile?.length ? companyProfile[0].companyName : undefined;
  const mktCap = companyProfile?.length ? companyProfile[0].mktCap : undefined;
  const cash = balanceSheetData?.length
    ? balanceSheetData[0].cashAndShortTermInvestments + balanceSheetData[0].longTermInvestments
    : undefined;
  const debt = balanceSheetData?.length ? balanceSheetData[0].totalDebt : undefined;
  const enterpriseValue = mktCap && cash && debt ? mktCap - cash + debt : undefined;

  // Extract 52-week low and high from range
  const range = companyProfile?.length ? companyProfile[0].range : '';
  const [low, high] = range.split('-').map(value => parseFloat(value.trim()));

  const metrics: { [key: string]: string | number | undefined } = {
    'Market Cap': shortNumberFormat(mktCap, 'suffix', 2),
    'Enterprise Value': shortNumberFormat(enterpriseValue, 'suffix', 2),
    'Cash & Investments': shortNumberFormat(cash, 'suffix', 2),
    'Debt & Capital Leases': shortNumberFormat(debt, 'suffix', 2),
    '52-Week High': `$${high}`,
    '52-Week Low': `$${low}`,
    [selectedRatio]: ratioValue,
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={`${className} bg-white shadow-md p-4`}>
      <h2 className="text-lg font-semibold">{companyName}</h2>
      <div className="grid grid-cols-1 gap-4 mt-4">
        {Object.entries(metrics).map(([label, value], index) => (
          <div key={index} className="grid grid-cols-2 gap-4">
            <p
              className={`text-sm font-semibold ${label === selectedRatio ? 'cursor-pointer text-primary-600' : ''}`}
              onClick={label === selectedRatio ? handleModalOpen : undefined}
            >
              {label}
            </p>
            <p className='text-sm font-semibold text-gray-500'>
              {value !== undefined && value !== null ? value : 'N/A'}
            </p>
          </div>
        ))}
      </div>
      <MetricModal
        isOpen={isModalOpen} 
        handleClose={handleModalClose} 
        selectedRatio={selectedRatio} 
        setSelectedRatio={setSelectedRatio}
      />
    </div>
  );
};
