import React from 'react';

interface BlockQuoteProps {
    comments: string;
    source: string;
}

export const BlockQuote: React.FC<BlockQuoteProps> = ({comments, source}) => {
    return <>
        <figure className="mt-10 border-l border-indigo-600 pl-9">
            <figcaption className="flex gap-x-4">
                <div className="text-baseleading-6">
                    <strong className="font-semibold text-indigo-500">{source}</strong> 
                </div>
            </figcaption>
            <blockquote className="pt-4 pb-4 font-base text-gray-600">
            <p className='text-base'>
                {comments}
            </p>
            </blockquote>
        </figure>
    </>
};
