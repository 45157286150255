import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { auth0Config } from './services/config/auth0';
import { useAuth0 } from '@auth0/auth0-react';

import { WatchlistPage } from './pages/WatchlistPage';
import { StreamingPage } from './pages/StreamingPage';
import { OverviewPage } from './pages/OverviewPage';
import { NotebookPage } from './pages/NotebookPage';
import { FinancialStatements } from './pages/FinancialStatements';
import { DocumentsPage } from './pages/DocumentsPage';
import { CompanyNewsPage } from './pages/CompanyNewsPage';
import { OptionsPage } from './pages/OptionsPage';
import { SandBoxPage } from './pages/SandboxPage';

import { ResearchTickerProviders } from './context/ResearchTickerProviders';
import { LoginPage } from './pages/LoginPage';
import { ProtectedRoute } from './components/Common/ProtectedRoute/ProtectedRoute';

// Callback component to handle the redirect from Auth0
const CallbackPage: React.FC = () => {
  const { isLoading, error } = useAuth0();

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return <Navigate to="/" />;
};

const AppContent: React.FC = () => (
  <Routes>
    <Route path="/" element={<Navigate to="/watchlist" replace />} />
    <Route path="/login" element={<LoginPage />} />
    <Route path="/callback" element={<CallbackPage />} />
    <Route path="/watchlist" element={<ProtectedRoute element={<WatchlistPage />} />} />
    <Route path="/streams" element={<ProtectedRoute element={<StreamingPage />} />} />
    <Route path="/overview" element={<ProtectedRoute element={<OverviewPage />} />} />
    <Route path="/notebook" element={<ProtectedRoute element={<NotebookPage />} />} />
    <Route path="/financials" element={<ProtectedRoute element={<FinancialStatements />} />} />
    <Route path="/documents" element={<ProtectedRoute element={<DocumentsPage />} />} />
    <Route path="/company-news" element={<ProtectedRoute element={<CompanyNewsPage />} />} />
    <Route path="/options" element={<ProtectedRoute element={<OptionsPage />} />} />
    <Route path="/sandbox" element={<ProtectedRoute element={<SandBoxPage />} />} />
  </Routes>
);

const App: React.FC = () => {
  return (
    <Auth0Provider
      domain={auth0Config.domain}
      clientId={auth0Config.clientId}
      authorizationParams={{
        redirect_uri: auth0Config.redirectUri,
      }}
    >
      <ResearchTickerProviders>
        <Router>
          <div className="flex flex-col h-screen">
            <Routes>
              <Route path="*" element={<AppContent />} />
            </Routes>
          </div>
        </Router>
      </ResearchTickerProviders>
    </Auth0Provider>
  );
};

export default App;
