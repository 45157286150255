import React from 'react';

interface TickerPillProps {
    ticker: string;
    onRemove: (ticker: string) => void;
}

export const TickerPill: React.FC<TickerPillProps> = ({ ticker, onRemove }) => {
    return (
        <div className="bg-primary-600 text-white rounded-full px-3 py-1 flex items-center gap-2">
            <span>{ticker}</span>
            <button onClick={() => onRemove(ticker)} className="text-white">
                &times;
            </button>
        </div>
    );
};
