import React, { useState } from 'react';
import { MetricSelector } from "./MetricSelector";
import { SolidButton } from "../Common/Buttons/SolidButton";
import { InputBox } from "../Common/InputBox/InputBox";
import { Dropdown } from "../Common/Dropdowns/Dropdown";
import { addRatioConfig } from "../../utils/math/ratios/addRatioConfig";
import { useGetRatioConfigs } from "../../utils/hooks/useGetRatioConfigs";
import { API_URL } from '../../services/config/apiConfig';

export const CreateNewRatio: React.FC = () => {
    const [ratioName, setRatioName] = useState('');
    const [numerator, setNumerator] = useState('');
    const [denominator, setDenominator] = useState('');
    const [ , setDescription] = useState('');
    const [ratioFormat, setRatioFormat] = useState<'number' | 'percentage' | 'currency'>('number');

    const { ratioConfigs, refreshRatioConfigs } = useGetRatioConfigs();

    const handleCreateRatio = async () => {
        const prompt = `Write a description for the ratio of ${numerator} divided by ${denominator}`;
        try {
            const response = await fetch(`${API_URL}/api/getRatioDescription`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ message: prompt })
            });
            const data = await response.json();
            if (ratioName && numerator && denominator && ratioFormat && data.description) {
                addRatioConfig(ratioConfigs, ratioName, numerator, denominator, ratioFormat, data.description);
                refreshRatioConfigs();
                setRatioName('');
                setNumerator('');
                setDenominator('');
                setDescription('');
                setRatioFormat('number');
            } else {
                console.error('Please fill in all fields to create the ratio.');
            }
        } catch (error) {
            console.error('Error during OpenAI request:', error);
        }
    };

    const formatOptions = ['Number', 'Percentage', 'Currency'];

    const handleRatioFormatChange = (format: string) => {
        switch (format.toLowerCase()) {
            case 'number':
                setRatioFormat('number');
                break;
            case 'percentage':
                setRatioFormat('percentage');
                break;
            case 'currency':
                setRatioFormat('currency');
                break;
            default:
                setRatioFormat('number');
                break;
        }
    };

    return (
        <>
            <div className="w-full flex flex-row items-start gap-8 mb-4">
                <InputBox
                    label="Ratio Name"
                    placeholder="Enter a name for the ratio"
                    onChange={(value) => setRatioName(value)} // Pass value directly from InputBox
                />
                <div className="w-full">
                    <h3 className="font-bold text-lg text-gray-700 mb-1">Ratio Format</h3>
                    <Dropdown
                        value={ratioFormat}
                        onChange={handleRatioFormatChange}
                        options={formatOptions}
                        className="w-full"
                    />
                </div>
            </div>
            
            <h3 className="text-xl font-semibold text-gray-900 mb-4 w-full">Numerator</h3>
            <MetricSelector onChange={(metric: string) => setNumerator(metric)} />

            <div className="w-full border-b border-gray-200 my-4"></div>

            <h3 className="text-xl font-semibold text-gray-900 mb-4 w-full">Denominator</h3>
            <MetricSelector onChange={(metric: string) => setDenominator(metric)} />
            <SolidButton className="mt-4" content="Create Ratio" onClick={handleCreateRatio} />
        </>
    );
};
