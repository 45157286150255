import React, { useEffect, useState, useMemo } from 'react';
import { calculatePutOptionBasis } from '../../utils/math/calculatePutOptionBasis';
import { calculatePutReturnToExpiry } from '../../utils/math/calculatePutReturnToExpiry';
import { calculateDiscountToStockPrice } from '../../utils/math/calculateDiscountToStockPrice';
import { useRiskFreeRate } from '../../context/RiskFreeRateContext';
import { TableCell } from '../Common/TableCell/TableCell';
import { OptionsExpandedRow } from './OptionsExpandedRow';

interface OptionsTableRowProps {
    ticker: string;
    strikePrice: string;
    bid: string;
    ask: string;
    mid: string;
    stock_price: number;
    expirationString: string;
}

const formatReturnToExpiry = (value: number): string => {
  const percentage = value * 100;
  if (percentage > 100) {
    return '>100%';
  }
  return `${percentage.toFixed(1)}%`;
};

export const OptionsTableRow: React.FC<OptionsTableRowProps> = ({ ticker, strikePrice, bid, ask, mid, stock_price, expirationString }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const { riskFreeRate } = useRiskFreeRate();
    const strikePriceNumber = Number(strikePrice);
    const midNumber = Number(mid);

    const expirationDate = useMemo(() => new Date(expirationString), [expirationString]);

    const [returnToExpiry, setReturnToExpiry] = useState<number | null>(null);

    useEffect(() => {
        const calculatedReturnToExpiry = calculatePutReturnToExpiry(strikePriceNumber, midNumber, expirationDate, riskFreeRate);
        setReturnToExpiry(calculatedReturnToExpiry);
    }, [strikePriceNumber, midNumber, expirationDate, riskFreeRate]);

    const discountToStockPrice = calculateDiscountToStockPrice(strikePriceNumber, midNumber, stock_price);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <>
            <tr className={`${isExpanded ? 'bg-primary-100' : 'bg-white hover:bg-gray-100'} cursor-pointer transition`} onClick={toggleExpand}>
                <td className="p-3 w-4">
                    <svg data-accordion-icon="" className="w-6 h-6 shrink-0 transition-transform duration-500 transform" fill="currentColor" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" style={{ transformOrigin: '65% 50%', transform: `${isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'}` }}>
                        <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                </td>
                <TableCell content={ticker} />
                <TableCell content={`$${strikePrice}`} />
                <TableCell content={`$${bid}`} />
                <TableCell content={`$${ask}`} />
                <TableCell content={`$${mid}`} />
                <TableCell content={`$${calculatePutOptionBasis(strikePriceNumber, midNumber).toFixed(2)}`} />
                <TableCell content={discountToStockPrice} />
                <TableCell content={expirationDate.toLocaleDateString()} />
                <td className="px-4 py-3 whitespace-nowrap">
                    {returnToExpiry !== null && (
                        <div data-tooltip-id="tooltip" data-tooltip-content={`${(returnToExpiry * 100).toFixed(1)}%`}>
                            <div className="w-fit bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded">
                                {formatReturnToExpiry(returnToExpiry)}
                            </div>
                        </div>
                    )}
                </td>
            </tr>
            {isExpanded && (
                <tr className="bg-primary-100">
                    <td colSpan={10}>
                        <div className="p-4">
                            <OptionsExpandedRow ticker={ticker} netCost={calculatePutOptionBasis(strikePriceNumber, midNumber)} />
                        </div>
                    </td>
                </tr>
            )}
        </>
    );
};
