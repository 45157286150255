import React from 'react';
import { NavLink } from 'react-router-dom';

interface SidebarLinkProps {
  to: string;
  children: React.ReactNode;
  icon: React.ReactNode; // Parameter for the icon
}

export const SidebarLink: React.FC<SidebarLinkProps> = ({ to, children, icon }) => {
  return (
    <li>
      <NavLink
        to={to}
        className={({ isActive }) =>
          isActive
            ? 'flex items-center px-4 py-1 text-primary-500 border-l-2 border-primary-500 bg-gray-50 font-semibold'
            : 'flex items-center px-4 py-1 text-gray-600 border-l-2 border-gray-50 hover:text-gray-600 hover:border-gray-300 hover:bg-gray-100'
        }
      >
        <span className="mr-3">{icon}</span> {/* Icon positioned here */}
        {children}
      </NavLink>
    </li>
  );
};
