import { OptionDetail, OptionSnapshotResponse } from "../../types/OptionChainType";

export async function getOptionsSnapshot(ticker: string): Promise<OptionSnapshotResponse> {
  const POLYGON_API_KEY = process.env.REACT_APP_POLYGON_API_KEY;
  let url = `https://api.polygon.io/v3/snapshot/options/${ticker}?contract_type=put&apiKey=${POLYGON_API_KEY}`;
  let allResults: OptionDetail[] = [];

  try {
      while (url) {
          const response = await fetch(url, {
              method: 'GET',
              headers: {
                  'Content-Type': 'application/json',
              },
          });

          if (!response.ok) {
              throw new Error(`Error: ${response.status}`);
          }

          const data: OptionSnapshotResponse = await response.json();
          allResults = allResults.concat(data.results);
          url = data.next_url ? `${data.next_url}&apiKey=${POLYGON_API_KEY}` : "";
      }

      return { results: allResults };
  } catch (error) {
      console.error("Failed to fetch the options snapshot: ", error);
      throw error;
  }
}
