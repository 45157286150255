export const calculateTTMArray = (quarters: number[]): number[] => {
    const TTMArray: number[] = [];
    
    for (let i = 0; i < quarters.length; i += 4) {
        if (i + 4 <= quarters.length) {
            const sum = quarters.slice(i, i + 4).reduce((acc, num) => acc + num, 0);
            TTMArray.push(sum);
        }
    }

    return TTMArray;
};
