import { useEffect, useState } from 'react';
import { getCompanyProfile } from '../../api/fmp/getCompanyProfile';
import { getSharesOutstanding } from '../../api/fmp/getSharesOutstanding';
import { getFullQuote } from '../../api/fmp/getFullQuote';
import { getQuarterlyBalanceSheet } from '../../api/fmp/getQuarterlyBalanceSheet';
import { useWatchlist } from '../../context/WatchlistContext/WatchlistContext';

interface UseFetchWatchlistDataResult {
    companyProfiles: any[];
    sharesOutstanding: any[];
    fullQuote: any[];
    balanceSheet: any[];
    isLoading: boolean;
}

export const useFetchWatchlistData = (): UseFetchWatchlistDataResult => {
    const { watchlist } = useWatchlist();
    const [companyProfiles, setCompanyProfiles] = useState<any[]>([]);
    const [sharesOutstanding, setSharesOutstanding] = useState<any[]>([]);
    const [fullQuote, setFullQuote] = useState<any[]>([]);
    const [balanceSheet, setBalanceSheet] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const profiles = await Promise.all(watchlist.tickers.map(ticker => getCompanyProfile(ticker)));
                const shares = await Promise.all(watchlist.tickers.map(ticker => getSharesOutstanding(ticker)));
                const quotes = await Promise.all(watchlist.tickers.map(ticker => getFullQuote(ticker)));
                const balanceSheets = await Promise.all(watchlist.tickers.map(ticker => getQuarterlyBalanceSheet(ticker)));
                
                setCompanyProfiles(profiles);
                setSharesOutstanding(shares);
                setFullQuote(quotes);
                setBalanceSheet(balanceSheets);

                // Save to sessionStorage
                sessionStorage.setItem('companyProfiles', JSON.stringify(profiles));
                sessionStorage.setItem('sharesOutstanding', JSON.stringify(shares));
                sessionStorage.setItem('fullQuote', JSON.stringify(quotes));
                sessionStorage.setItem('balanceSheet', JSON.stringify(balanceSheets));
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setIsLoading(false);
            }
        };

        // Check if data is available in sessionStorage
        const cachedCompanyProfiles = sessionStorage.getItem('companyProfiles');
        const cachedSharesOutstanding = sessionStorage.getItem('sharesOutstanding');
        const cachedFullQuote = sessionStorage.getItem('fullQuote');
        const cachedBalanceSheet = sessionStorage.getItem('balanceSheet');

        if (cachedCompanyProfiles && cachedSharesOutstanding && cachedFullQuote && cachedBalanceSheet) {
            setCompanyProfiles(JSON.parse(cachedCompanyProfiles));
            setSharesOutstanding(JSON.parse(cachedSharesOutstanding));
            setFullQuote(JSON.parse(cachedFullQuote));
            setBalanceSheet(JSON.parse(cachedBalanceSheet));
            setIsLoading(false);
        } else {
            fetchData();
        }
    }, [watchlist.tickers]);

    return {
        companyProfiles,
        sharesOutstanding,
        fullQuote,
        balanceSheet,
        isLoading,
    };
};
