import React, { useState } from 'react';
import { BlockNoteEditor } from "@blocknote/core";
import { BlockNoteView, useBlockNote } from "@blocknote/react";
import { ContentBlock } from '../../Common/ContentBlock/ContentBlock';

type TabKey = 'Management Comments' | 'Internal Notes';

export const TableCellNavBar: React.FC = () => {
    const [activeTab, setActiveTab] = useState<TabKey>('Management Comments');
    const editor: BlockNoteEditor = useBlockNote({});
   
    const isActive = (tabName: string) => activeTab === tabName ? 'text-indigo-600 border-b-2 border-indigo-600 rounded-t-lg active' : 'text-gray-500 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300';

    const renderCellData = () => {
            
        switch (activeTab) {
            case 'Management Comments':
                return (<>
                    <div className="mx-8">
                        <ContentBlock row_title={'Test Row Data'} index={0} value={'selectedValue'}/>
                            <div className="flex justify-center mb-16">
                                <button
                                    type="button"
                                    className="text-white bg-indigo-600 hover:bg-indigo-800 font-medium rounded-lg text-sm px-5 py-2.5"
                                    /* onClick={() => handleClose()} */
                                >
                                    Close
                                </button>
                            </div>
                    </div>
                </>)
            case 'Internal Notes':
                return (<>
                    <div>
                        <h1 className="mx-14 mb-4 text-xl font-bold leading-none text-indigo-600 sm:text-2xl">Internal Notes</h1>
                        <BlockNoteView editor={editor} />
                    </div>
                </>)
            default:
                return null;
        }
    }

    return <>
        <div className="w-fit text-sm font-medium text-center text-gray-500 border-b border-gray-200 mt-2 mx-16">
            <ul className="flex flex-wrap -mb-px">
                <li className="me-2">
                    <button onClick={() => setActiveTab('Management Comments')} className={`inline-block p-4 border-b-2 rounded-t-lg ${isActive('Management Comments')}`}>
                        Management Comments
                    </button>
                </li>
                <li className="me-2">
                        <button onClick={() => setActiveTab('Internal Notes')} className={`inline-block p-4 border-b-2 rounded-t-lg ${isActive('Internal Notes')}`}>
                            Internal Notes
                        </button>
                </li>
            </ul>
        </div>
        <div className="mt-6">
            {renderCellData()}
        </div>        
    </>
};
