import React from 'react';
import { BlockQuote } from '../BlockQuote/BlockQuote';

interface ContentBlockProps {
    row_title: string | null;
    index: number | null;
    value: string | null;
}

export const ContentBlock: React.FC<ContentBlockProps> = ({ row_title, value })  => {
    
  return <>
    <section className="bg-white">
        <div className="w-full mx-auto max-w-6xl">
            <h2 className="w-full mb-4 text-base font-bold leading-none text-indigo-700 sm:text-2xl">Management Comments</h2>
            <div className="flex justify-center items-center h-full">
                <BlockQuote
                    comments = "As we reflect on this past year, I'm thrilled to announce that Microsoft's revenue has surged by an impressive 39% year over year, a testament to our team's hard work and dedication. This remarkable growth can be attributed to our strategic expansion in cloud computing and AI technologies, which have resonated strongly with our customer base, driving increased adoption across multiple sectors. Furthermore, our commitment to innovation and continuously enhancing user experience in our core products, like Windows and Office, has significantly contributed to boosting our market presence and customer loyalty, leading to this outstanding financial performance."
                    source = "2023 10-K, Page 11"
                />
            </div>
        </div>
    </section>
</>
}
