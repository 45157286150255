const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

if (!domain) {
  throw new Error("Missing Auth0 domain");
}

if (!clientId) {
  throw new Error("Missing Auth0 client ID");
}

export const auth0Config = {
  domain: domain,
  clientId: clientId,
  redirectUri: window.location.origin + '/callback',
};
