import React from 'react';
import { MainNavBar } from '../../NavBars/MainNavBar/MainNavBar';
import { Sidebar } from '../../Sidebar/Sidebar';

interface ProtectedLayoutProps {
  children: React.ReactNode;
}

export const ProtectedLayout: React.FC<ProtectedLayoutProps> = ({ children }) => {
  return (
    <div className="flex flex-col h-screen">
      <MainNavBar />
      <div className="flex flex-grow">
        <Sidebar />
        <div className="flex-grow p-5">
          {children}
        </div>
      </div>
    </div>
  );
};
