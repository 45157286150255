import React from 'react';

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
}

export const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="bg-white rounded-lg p-6 relative w-2/3">
                <button
                    className="absolute top-4 right-4 text-gray-600 text-2xl p-2 hover:text-gray-800 hover:bg-gray-200"
                    onClick={onClose}
                >
                    &times;
                </button>
                <div className="mt-4">  {/* Adjusted margin-top to create space below the close button */}
                    {children}
                </div>
            </div>
        </div>
    );
};
