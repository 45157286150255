import React, { createContext, useContext, useState, ReactNode, Dispatch, SetStateAction } from 'react';

interface SelectedCellInfo {
    row: string | null;
    column: number | null;
}

interface SelectedCellContextType {
    selectedCell: SelectedCellInfo;
    setSelectedCell: Dispatch<SetStateAction<SelectedCellInfo>>;
    error: Error | null;
    setError: Dispatch<SetStateAction<Error | null>>;
}

const defaultSelectedCell: SelectedCellInfo = {
    row: null,
    column: null,
};

const SelectedCellContext = createContext<SelectedCellContextType | undefined>(undefined);

export const SelectedCellProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [selectedCell, setSelectedCell] = useState<SelectedCellInfo>(defaultSelectedCell);
    const [error, setError] = useState<Error | null>(null);

    return (
        <SelectedCellContext.Provider value={{ selectedCell, setSelectedCell, error, setError }}>
            {children}
        </SelectedCellContext.Provider>
    );
};

export const useSelectedCell = () => {
    const context = useContext(SelectedCellContext);
    if (!context) {
        throw new Error('useSelectedCell must be used within a SelectedCellProvider');
    }
    return context;
};
