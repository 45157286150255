import React from 'react';
import { cleanFloat } from '../../../utils/formatting/cleanFloat/cleanFloat';

interface CurrencyInputBoxProps {
    placeholder?: string;
    label: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>) => void;
}

export const CurrencyInputBox: React.FC<CurrencyInputBoxProps> = ({ placeholder, label, onChange }) => {
    const [value, setValue] = React.useState('');

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;
        const cleanedValue = cleanFloat(target.value);
        setValue(cleanedValue);
        // Optionally call the onChange prop if needed
        onChange(e);
    };

    return (
        <div className="w-full flex flex-col items-start">
            <label className="font-bold text-sm text-gray-700 mb-1">{label}</label>
            <div className="w-full flex items-center">
                <span className="ml-2 text-sm text-gray-500">$</span>
                <input
                    type="text"
                    className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                    placeholder={placeholder}
                    onChange={handleChange}
                    value={value}
               />
            </div>
        </div>
    );
};
