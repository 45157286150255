export const cleanFloat = (value: string): string => {
    let cleanedValue = value.replace(/[^\d.]/g, ''); // Remove all non-numeric characters except "."
    const firstPeriodIndex = cleanedValue.indexOf('.');

    if (firstPeriodIndex !== -1) {
        cleanedValue = cleanedValue.substring(0, firstPeriodIndex + 1) +
            cleanedValue.substring(firstPeriodIndex + 1).replace(/\./g, ''); // Remove all subsequent periods
    }

    return cleanedValue;
}
