import React, { useState, useEffect } from 'react';
import { useSelectedCell } from '../../context/SelectedCellContext';
import "@blocknote/core/style.css";
import { TableCellNavBar } from '../NavBars/TableCellNavBar/TableCellNavBar';

interface FinancialsTableRowProps {
    row_title: string;
    values: string[];
}

export const FinancialsTableRow: React.FC<FinancialsTableRowProps> = ({ row_title, values }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const contextValue = useSelectedCell();
    const [ , setSelectedValue ] = useState<string | null>(null);


    if (contextValue === undefined) {
        throw new Error('useSelectedCell must be used within a SelectedCellContext');
    }
    
    const { selectedCell, setSelectedCell } = contextValue;

    useEffect(() => {
        if (isExpanded) {
            // Introduce a delay before logging the ref
            const timer = setTimeout(() => {
            }, 5); // A delay of 0ms is often enough
    
            return () => clearTimeout(timer); // Clear the timeout if the component unmounts
        }
    }, [isExpanded]);
    
    const [ , setFocusedCell] = useState<number | null>(null);
    const handleFocus = (index : number) => {
        setFocusedCell(index);
    };
    const handleBlur = () => {
        setFocusedCell(null);
    };
    
    const handleCellClick = (columnIndex: number, value: string) => {
        if (selectedCell?.row === row_title && selectedCell?.column === columnIndex) {
            setIsExpanded(!isExpanded);
            setSelectedCell({ row: null, column: null });
            setSelectedValue(null);
        } else {
            setSelectedCell({ row: row_title, column: columnIndex });
            setSelectedValue(value)
            if (!isExpanded) {
                setIsExpanded(true);
            }
        }
    };
    
    return (
        <>
            <tr className="row-hover cursor-pointer transition">
                <td className="text-left bg-white sticky left-0 px-4 py-3">
                    <div className="flex">
                        {row_title}                      
                    </div>
                </td>
                {values.map((value, index) => (
                    <td
                        key={index}
                        className={`select-none px-4 py-3 ${selectedCell?.column === index && selectedCell?.row === row_title ? 'focused-cell' : ''}`}
                        tabIndex={0}
                        onFocus={() => handleFocus(index)}
                        onBlur={handleBlur}
                        onClick={() => handleCellClick(index, value)}
                    >
                        <div className="text-right">
                            {value}                  
                        </div>
                    </td>
                ))}
            </tr>

            {isExpanded && (
                <tr>
                    <td colSpan={7} className="sticky left-0 w-viewport bg-white text-left">
                        <TableCellNavBar />
                    </td>
                </tr>
            )}
        </>
    );
};
