import React, { useState, useEffect } from 'react';
import { BarChart } from '../Common/BarChart/BarChart';
import { OptionsMetricsCard } from './OptionsMetricsCard';
import { getCompanyProfile } from '../../api/fmp/getCompanyProfile';
import { FMPCompanyProfile } from '../../types/FMPCompanyProfile';
import { NetCostProvider } from '../../context/NetCostContext';

interface OptionsExpandedRowProps {
    ticker: string;
    netCost: number;
}

export const OptionsExpandedRow: React.FC<OptionsExpandedRowProps> = ({ ticker, netCost }) => {
    const [, setProfile] = useState<FMPCompanyProfile[] | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const profileResponse = await getCompanyProfile(ticker);
                setProfile(profileResponse.data);
            } catch (error) {
                console.error('Error fetching company profile:', error);
            }
        };

        fetchData();
    }, [ticker]);

    return (
        <NetCostProvider>
            <div className="w-full grid grid-cols-3 gap-4">
                <OptionsMetricsCard ticker={ticker} className="col-span-1" initialNetCost={netCost} />
                <BarChart ticker={ticker} />
            </div>
        </NetCostProvider>
    );
};
