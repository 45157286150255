import React from 'react';
import { MainContainer } from '../components/Containers/MainContainer';
import { WatchlistProvider } from '../context/WatchlistContext/WatchlistContext';
import { WatchlistType } from '../types/WatchlistType';
import { WatchlistManagement} from '../components/WatchlistTable/WatchlistManagement';
import { LoadingTable } from '../components/Common/LoadingTable/LoadingTable';
import { useWatchlist } from '../context/WatchlistContext/WatchlistContext';
import { WatchlistTable } from '../components/WatchlistTable/WatchlistTable';
import { WatchlistControls } from '../components/WatchlistTable/WatchlistControls';
import { WatchlistSearchProvider } from '../context/WatchlistContext/WatchlistSearchContext';
import { WatchlistAddTickersProvider } from '../context/WatchlistContext/WatchlistAddTickerContext';
import { WatchlistTableRatioProvider } from '../context/WatchlistContext/WatchlistTableRatioContext';

const WatchlistContent: React.FC = () => {
  const { watchlist, isLoading } = useWatchlist();

  console.log('Watchlist:', watchlist);

  return (
      <MainContainer>
        <div className='flex items-center gap-4 mb-4'>
          <h1 className="text-2xl font-bold">Watchlist</h1>
          <WatchlistManagement />
        </div>
        <div className="flex flex-col gap-4">
          {isLoading && <LoadingTable />}
          {!isLoading && (
            <>
              <WatchlistControls />
              <WatchlistTable />
            </>
          )}
        </div>
      </MainContainer>
  );
};

export const WatchlistPage: React.FC = () => {
  const initialWatchlist: WatchlistType = {
    id: '6667b0032083ae3886a221fe',
    tickers: [],
    userId: '',
    watchlistName: 'Enryched Watchlist',
  };

  return (
    <WatchlistProvider initialWatchlist={initialWatchlist}>
    <WatchlistSearchProvider>
    <WatchlistAddTickersProvider>
    <WatchlistTableRatioProvider>
            <WatchlistContent />
    </WatchlistTableRatioProvider>
    </WatchlistAddTickersProvider>
    </WatchlistSearchProvider>
    </WatchlistProvider>
  );
};
