import React, { createContext, useContext, useState, ReactNode } from 'react';

interface TickerContextType {
  researchTicker: string;
  setResearchTicker: (researchTicker: string) => void;
}

const TickerContext = createContext<TickerContextType | undefined>(undefined);

export const TickerProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [researchTicker, setResearchTicker] = useState<string>('');

  return (
    <TickerContext.Provider value={{ researchTicker, setResearchTicker }}>
      {children}
    </TickerContext.Provider>
  );
};

export const useResearchTicker = () => {
  const context = useContext(TickerContext);
  if (!context) {
    throw new Error('useResearchTicker must be used within a ResearchTickerProvider');
  }
  return context;
};
