import { Units } from '../determineUnits/determineUnits';
import { formatToPrecision } from '../formatToPrecision/formatToPrecision';

export const convertToDisplayUnits = (value: number, unit: Units): number => {
    if(isNaN(value)) return NaN;
    
    value = formatToPrecision(value, 4);

    switch (unit) {
        case 'in trillions':
            return (value / 1e12);
        case 'in billions':
            return (value / 1e9);
        case 'in millions':
            return (value / 1e6);
        case 'in thousands':
            return (value / 1e3);
        case 'as is':
            return value;
        default:
            return value;
    }
};
