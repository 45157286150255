import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faChartBar, faChartLine, faFileAlt, faHome, faInfoCircle, faList, faNewspaper, faPlay, faSearch, faWrench } from '@fortawesome/free-solid-svg-icons';

interface IconProps {
  className?: string;
}

export const DocumentIcon: React.FC<IconProps> = ({ className }) => <FontAwesomeIcon icon={faFileAlt} className={className} />;
export const FinancialIcon: React.FC<IconProps> = ({ className }) => <FontAwesomeIcon icon={faChartLine} className={className} />;
export const HomeIcon: React.FC<IconProps> = ({ className }) => <FontAwesomeIcon icon={faHome} className={className} />;
export const NewsIcon: React.FC<IconProps> = ({ className }) => <FontAwesomeIcon icon={faNewspaper} className={className} />;
export const NotebookIcon: React.FC<IconProps> = ({ className }) => <FontAwesomeIcon icon={faBook} className={className} />;
export const OptionsIcon: React.FC<IconProps> = ({ className }) => <FontAwesomeIcon icon={faChartBar} className={className} />;
export const OverviewIcon: React.FC<IconProps> = ({ className }) => <FontAwesomeIcon icon={faInfoCircle} className={className} />;
export const SandboxIcon: React.FC<IconProps> = ({ className }) => <FontAwesomeIcon icon={faWrench} className={className} />;
export const ScreenerIcon: React.FC<IconProps> = ({ className }) => <FontAwesomeIcon icon={faSearch} className={className} />;
export const StreamsIcon: React.FC<IconProps> = ({ className }) => <FontAwesomeIcon icon={faPlay} className={className} />;
export const WatchlistIcon: React.FC<IconProps> = ({ className }) => <FontAwesomeIcon icon={faList} className={className} />;
