import React, { createContext, useContext, useState, ReactNode, Dispatch, SetStateAction } from 'react';

interface CalculatedMetric {
    name: string;
    value: number;
}

interface CalculatedMetricsContextType {
    calculatedMetrics: CalculatedMetric[];
    setCalculatedMetrics: Dispatch<SetStateAction<CalculatedMetric[]>>;
    error: Error | null;
    setError: Dispatch<SetStateAction<Error | null>>;
}

const CalculatedMetricsContext = createContext<CalculatedMetricsContextType | undefined>(undefined);

export const CalculatedMetricsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [calculatedMetrics, setCalculatedMetrics] = useState<CalculatedMetric[]>([]);
    const [error, setError] = useState<Error | null>(null);

    return (
        <CalculatedMetricsContext.Provider value={{ calculatedMetrics, setCalculatedMetrics, error, setError }}>
            {children}
        </CalculatedMetricsContext.Provider>
    );
};

export const useCalculatedMetrics = () => {
    const context = useContext(CalculatedMetricsContext);
    if (!context) {
        throw new Error('useCalculatedMetrics must be used within a CalculatedMetricsProvider');
    }
    return context;
};
