import React, { createContext, useState, Dispatch, SetStateAction, ReactNode, useContext } from 'react';

// Define the shape of the context value
interface RiskFreeRateContextType {
    riskFreeRate: number;
    setRiskFreeRate: Dispatch<SetStateAction<number>>;
}

// Create the context
const RiskFreeRateContext = createContext<RiskFreeRateContextType | undefined>(undefined);

export const RiskFreeRateProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [riskFreeRate, setRiskFreeRate] = useState<number>(0.05);

    return (
        <RiskFreeRateContext.Provider value={{ riskFreeRate, setRiskFreeRate }}>
            {children}
        </RiskFreeRateContext.Provider>
    );
};

export const useRiskFreeRate = () => {
    const context = useContext(RiskFreeRateContext);
    if (!context) {
        throw new Error('useRiskFreeRate must be used within a RiskFreeRateProvider');
    }
    return context;
};
