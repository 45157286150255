import React, { useState } from 'react';
import { PlusButton } from '../Common/Buttons/PlusButton';
import { Modal } from '../Common/Modal/Modal';
import { AddTickerModal } from './AddTickerModal';

export const AddTickers: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="flex flex-col gap-4">
            <div className="flex items-center gap-2">
                <PlusButton content="Add Tickers" onClick={handleOpenModal} />
            </div>
            <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
                <AddTickerModal onClose={handleCloseModal} />
            </Modal>
        </div>
    );
};
