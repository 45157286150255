// financialStatementUtils.ts

import { FMPIncomeStatementType } from '../../../types/FMPIncomeStatement';
import { FMPCashFlowStatementType } from '../../../types/FMPCashFlowStatement';
import { FMPBalanceSheetStatementType } from '../../../types/FMPBalanceSheetStatement';

type FinancialStatementType = FMPIncomeStatementType | FMPCashFlowStatementType | FMPBalanceSheetStatementType;

export const extractMetricData = <
  T extends FinancialStatementType,
  K extends keyof T
>(
  data: T[],
  key: K
): T[K][] => {
  return data.map(item => item[key]);
};
