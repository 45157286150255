import { useState, useEffect } from 'react';
import { BarChart } from '../../Common/BarChart/BarChart';
import { MetricsCard } from '../MetricsCard/MetricsCard';
import { getCompanyProfile } from '../../../api/fmp/getCompanyProfile';
import { FMPCompanyProfile } from '../../../types/FMPCompanyProfile';

interface ExpandedRowProps {
    ticker: string;
}

export const ExpandedRow: React.FC<ExpandedRowProps> = ({ ticker }) => {
    const [ , setProfile] = useState<FMPCompanyProfile[] | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const profileResponse = await getCompanyProfile(ticker);
                setProfile(profileResponse.data);
            } catch (error) {
                console.error('Error fetching company profile:', error);
            }
        };

        fetchData();
    }, [ticker]);

    return (
        <div className="w-full grid grid-cols-3 gap-4">
            <MetricsCard ticker={ticker} className="col-span-1" />
            <div className="col-span-2 h-auto">
                <BarChart ticker={ticker} />
            </div>
        </div>
    );
};
