import React, { useMemo, useState } from 'react';
import { WatchlistTableHeader } from './WatchlistTableHeader';
import { WatchlistTableRow } from './WatchlistTableRow';
import { LoadingTable } from '../Common/LoadingTable/LoadingTable';
import { NetCostProvider } from '../../context/NetCostContext';
import { useFetchWatchlistData } from '../../utils/hooks/useFetchWatchlistData';
import { mapWatchlistData } from '../../utils/dataProcessing/mapWatchlistData';
import { sortWatchlistData } from '../../utils/dataProcessing/sortWatchlistData';
import { WatchlistRowType } from '../../types/WatchlistRowType';
import { useWatchlist } from '../../context/WatchlistContext/WatchlistContext';
import { useWatchlistSearch } from '../../context/WatchlistContext/WatchlistSearchContext';
import { useWatchlistTableRatio } from '../../context/WatchlistContext/WatchlistTableRatioContext';

type SortConfig = { key: keyof WatchlistRowType | 'selectedRatio', direction: 'asc' | 'desc' };

export const WatchlistTable: React.FC = () => {
    const { isLoading: contextLoading } = useWatchlist();
    const { searchQuery } = useWatchlistSearch();
    const { companyProfiles, sharesOutstanding, fullQuote, balanceSheet, isLoading: dataLoading } = useFetchWatchlistData();
    const { ratios } = useWatchlistTableRatio();
    const [sortConfig, setSortConfig] = useState<SortConfig | null>(null);
    const [selectedRatio, setSelectedRatio] = useState('Earnings Yield');

    const isLoading = contextLoading || dataLoading;

    // Memoize the rows to ensure they are recalculated only when the dependencies change
    const rows: WatchlistRowType[] = useMemo(() => {
        const mappedRows = mapWatchlistData(companyProfiles, sharesOutstanding, fullQuote, balanceSheet, ratios, selectedRatio);
        let sortedRows = sortWatchlistData(mappedRows, sortConfig, ratios, selectedRatio);

        if (!searchQuery) return sortedRows;

        const lowerCaseQuery = searchQuery.toLowerCase();

        return sortedRows.filter(row =>
            (row.ticker && row.ticker.toLowerCase().includes(lowerCaseQuery)) ||
            (row.company && row.company.toLowerCase().includes(lowerCaseQuery))
        );
    }, [companyProfiles, sharesOutstanding, fullQuote, balanceSheet, sortConfig, ratios, searchQuery, selectedRatio]);

    // Handler for sorting the table based on the clicked column header
    const handleSort = (key: keyof WatchlistRowType | 'selectedRatio') => {
        let direction: 'asc' | 'desc' = 'asc';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    return (
        <NetCostProvider>
            {isLoading ? (
                <LoadingTable />
            ) : (
                <table className="table-auto w-full">
                    <WatchlistTableHeader
                        selectedRatio={selectedRatio}
                        setSelectedRatio={setSelectedRatio}
                        onSort={handleSort}
                        sortConfig={sortConfig}
                    />
                    <tbody>
                        {rows.map(row => (
                            <WatchlistTableRow
                                key={row.ticker}
                                ticker={row.ticker}
                                company={row.company}
                                stock_price={row.stock_price}
                                percent_change={row.percent_change}
                                sharesOutstanding={row.sharesOutstanding}
                                marketCap={row.marketCap}
                                enterpriseValue={row.enterpriseValue}
                                selectedRatio={selectedRatio}
                            />
                        ))}
                    </tbody>
                </table>
            )}
        </NetCostProvider>
    );
};
