import React from 'react';

interface ModalPopProps {
  isOpen: boolean;
  handleClose: () => void;
  children: React.ReactNode;
}

export const ModalPop: React.FC<ModalPopProps> = ({ isOpen, handleClose, children }) => {
  const handleOuterClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  return isOpen ? (
    <div
      aria-hidden="true"
      className="fixed inset-0 z-50 flex items-start justify-center w-full h-full bg-gray-500 bg-opacity-75"
      onClick={handleOuterClick}
    >
      <div className="relative w-full max-w-3xl bg-white rounded-lg shadow sm:p-5 overflow-y-hidden max-h-[80vh] mt-16 mx-4">
        {children}
      </div>
    </div>
  ) : null;
};
