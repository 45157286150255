// utils/formatters.ts
export const shortNumberFormat = (value: number | undefined, format: 'suffix' | 'noSuffix', numDecimalPoints: number): string | number => {
    if (value === undefined) return 'N/A';
    
    const absValue = Math.abs(value);
    let suffixStr = '';
    let formattedValue = absValue;
  
    if (absValue >= 1e12) {
      formattedValue = absValue / 1e12;
      suffixStr = 'T';
    } else if (absValue >= 1e9) {
      formattedValue = absValue / 1e9;
      suffixStr = 'B';
    } else if (absValue >= 1e6) {
      formattedValue = absValue / 1e6;
      suffixStr = 'M';
    } else if (absValue >= 1e3) {
      formattedValue = absValue / 1e3;
      suffixStr = 'K';
    }
  
    const sign = value < 0 ? '-' : '';
    if (format === 'suffix') {
      return `${sign}$${formattedValue.toFixed(numDecimalPoints)}${suffixStr}`;
    } else {
      return parseFloat(`${sign}${formattedValue.toFixed(numDecimalPoints)}`);
    }
  };
  