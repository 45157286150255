interface FieldItemProps {
    field: string;
    index: number;
    handleFieldSelect: (metric: string, index: number) => void;
}

export const FieldItem: React.FC<FieldItemProps> = ({ field, index, handleFieldSelect }) => {
    return (
        <button
            key={index}
            onClick={() => handleFieldSelect(field, index)}
            className="w-full flex items-center p-2 border-gray-200 bg-gray-50 hover:bg-primary-100 focus:bg-primary-200 focus:outline-none"
        >
            <span className="text-gray-900">{field}</span>
        </button>
    );
};
