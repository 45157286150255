// src/services/config/apiConfig.js

const isProduction = process.env.NODE_ENV === 'production';

console.log("process.env.NODE_ENV:", process.env.NODE_ENV);
console.log("API URL is set to:", isProduction ? "Production" : "Local");

export const API_URL = isProduction
  ? process.env.REACT_APP_API_URL_PRODUCTION
  : process.env.REACT_APP_API_URL_LOCAL;
