type Order = 'asc' | 'desc';

export const calculateYoYChange = (data: number[], quarterly: boolean, order: Order = 'desc'): number[] => {
    const changes: number[] = [];
    const len = data.length;

    if (quarterly === false) {
        for (let i = 0; i < len - 1; i++) {
            const index = order === 'desc' ? i : i + 1;
            const nextIndex = order === 'desc' ? i + 1 : i;
            const change = (data[index] - data[nextIndex]) / data[nextIndex];
            changes.push(change);
        }
    } else if (quarterly === true) {
        for (let i = 0; i < len - 4; i++) {
            const index = order === 'desc' ? i : i + 4;
            const nextIndex = order === 'desc' ? i + 4 : i;
            const change = (data[index] - data[nextIndex]) / data[nextIndex];
            changes.push(change);
        }
    } else {
        throw new Error('Invalid period type. Expected "annual" or "quarterly".');
    }

    return changes;
};
