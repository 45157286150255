import React, { createContext, useContext, useState, ReactNode, Dispatch, SetStateAction } from 'react';

interface WatchlistSearchContextType {
    searchQuery: string;
    setSearchQuery: Dispatch<SetStateAction<string>>;
}

const WatchlistSearchContext = createContext<WatchlistSearchContextType | undefined>(undefined);

export const WatchlistSearchProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [searchQuery, setSearchQuery] = useState<string>('');

    return (
        <WatchlistSearchContext.Provider value={{ searchQuery, setSearchQuery }}>
            {children}
        </WatchlistSearchContext.Provider>
    );
};

export const useWatchlistSearch = () => {
    const context = useContext(WatchlistSearchContext);
    if (!context) {
        throw new Error('useWatchlistSearch must be used within a WatchlistSearchProvider');
    }
    return context;
};
