export const calculateLastTTM = (quarters: number[]): number => {
    if (quarters.length < 4) {
      throw new Error('Not enough data to calculate TTM');
    }
  
    // Take the last four quarters
    const lastFourQuarters = quarters.slice(0, 4);
  
    // Calculate the sum of the last four quarters
    const TTM = lastFourQuarters.reduce((acc, num) => acc + num, 0);
  
    return TTM;
  };
  