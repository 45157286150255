import React, { createContext, useContext, useState, ReactNode, Dispatch, SetStateAction } from 'react';

interface OptionChainsContextType {
    optionChains: any[];
    setOptionChains: Dispatch<SetStateAction<any[]>>;
    error: Error | null;
    setError: Dispatch<SetStateAction<Error | null>>;
    isLoading: boolean;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
}

const OptionChainsContext = createContext<OptionChainsContextType | undefined>(undefined);

export const OptionChainsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [optionChains, setOptionChains] = useState<any[]>([]);
    const [error, setError] = useState<Error | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    return (
        <OptionChainsContext.Provider value={{ optionChains, setOptionChains, error, setError, isLoading, setIsLoading }}>
            {children}
        </OptionChainsContext.Provider>
    );
};

export const useOptionChains = () => {
    const context = useContext(OptionChainsContext);
    if (!context) {
        throw new Error('useOptionChains must be used within an OptionChainsProvider');
    }
    return context;
};
