import React, { useState } from 'react';
import { FinancialsTable } from '../../FinancialsTable/FinancialsTable';
import { useIncomeStatementData } from '../../../context/IncomeStatementContext';
import { useCashFlowStatementData } from '../../../context/CashFlowStatementContext';
import { useBalanceSheetData } from '../../../context/BalanceSheetContext';
import { NavBarLink } from '../NavBarLink';

type TabKey = 'income_statement' | 'cash_flow' | 'balance_sheet';

export const FinancialsNavBar: React.FC = () => {
    const [activeTab, setActiveTab] = useState<TabKey>('income_statement');
    const { incomeStatementData } = useIncomeStatementData();
    const { cashFlowStatementData } = useCashFlowStatementData();
    const { balanceSheetData } = useBalanceSheetData();

    const incomeStatement = incomeStatementData && incomeStatementData.length > 0 ? incomeStatementData : [];
    const cashFlow = cashFlowStatementData && cashFlowStatementData.length > 0 ? cashFlowStatementData : [];
    const balanceSheet = balanceSheetData && balanceSheetData.length > 0 ? balanceSheetData : [];

    const renderFinancialData = () => {
        switch (activeTab) {
            case 'income_statement':
                return <FinancialsTable financial_data={incomeStatement} />;
            case 'cash_flow':
                return <FinancialsTable financial_data={cashFlow} />;
            case 'balance_sheet':
                return <FinancialsTable financial_data={balanceSheet} />;
            default:
                return null;
        }
    };

    const isActive = (tabName: string) =>
        activeTab === tabName
            ? 'text-indigo-600 border-b-2 border-indigo-600 rounded-t-lg active'
            : 'text-gray-500 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300';

    return (
        <>
            <div className="w-fit text-sm font-medium text-center text-gray-500 border-b border-gray-200 mt-2">
                <ul className="flex flex-wrap -mb-px">
                    <li className="me-2">
                        <NavBarLink name="Income Statement" id="income_statement" setActiveTab={setActiveTab} isActive={isActive} />
                    </li>
                    <li className="me-2">
                        <NavBarLink name="Cash Flow" id="cash_flow" setActiveTab={setActiveTab} isActive={isActive} />
                    </li>
                    <li className="me-2">
                        <NavBarLink name="Balance Sheet" id="balance_sheet" setActiveTab={setActiveTab} isActive={isActive} />
                    </li>
                </ul>
            </div>
            <div className="mt-6">{renderFinancialData()}</div>
        </>
    );
};
