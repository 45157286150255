import React, { createContext, useContext, useState, ReactNode, Dispatch, SetStateAction } from 'react';

interface WatchlistAddTickersContextType {
    tickers: string[];
    setTickers: Dispatch<SetStateAction<string[]>>;
}

const WatchlistAddTickersContext = createContext<WatchlistAddTickersContextType | undefined>(undefined);

export const WatchlistAddTickersProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [tickers, setTickers] = useState<string[]>([]);

    return (
        <WatchlistAddTickersContext.Provider value={{ tickers, setTickers }}>
            {children}
        </WatchlistAddTickersContext.Provider>
    );
};

export const useWatchlistAddTickers = () => {
    const context = useContext(WatchlistAddTickersContext);
    if (!context) {
        throw new Error('useWatchlistAddTickers must be used within a WatchlistAddTickersProvider');
    }
    return context;
};
