export const generateTTMLabels = (array: any[]): string[] => {
    const numLabels = Math.floor(array.length / 4);
    const labels: string[] = [];

    for (let i = 0; i < numLabels; i++) {
        labels.push(i === 0 ? 'TTM' : `TTM -${i}`);
    }

    return labels;
};
