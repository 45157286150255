import React, { useEffect, useState } from 'react';
import { getSECFilings } from '../../../../api/fmp/getSECFilings';
import { useResearchTicker } from '../../../../context/TickerContext';

interface OtherFilingsListProps {
    onSelect: (url: string, title: string) => void;
}

export const OtherFilingsList: React.FC<OtherFilingsListProps> = ({ onSelect }) => {
    const [documents, setDocuments] = useState<any[]>([]);
    const { researchTicker } = useResearchTicker();

    useEffect(() => {
        const fetchDocuments = async () => {
            try {
                const response = await getSECFilings(researchTicker);
                console.log('response: ', response);

                if (response.data && Array.isArray(response.data)) {
                    const otherFilings = response.data
                        .filter((doc: any) => doc.type !== '10-K' && doc.type !== '8-K')
                        .map((doc: any) => {
                            const date = new Date(doc.fillingDate).toLocaleDateString();
                            return {
                                period: `${doc.type} ${date}`,
                                date: date,
                                link: doc.finalLink,
                                type: doc.type
                            };
                        });

                    setDocuments(otherFilings);
                }
            } catch (error) {
                console.error('Failed to fetch documents:', error);
            }
        };

        fetchDocuments();
    }, [researchTicker]);

    return (
        <ul className="space-y-2 h-64 overflow-y-auto">
            {documents.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()).map((doc, index) => (
                <li
                    key={index}
                    className="flex items-center text-sm hover:bg-gray-100 px-2 py-1 cursor-pointer gap-4"
                    onClick={() => onSelect(doc.link, doc.period)}
                >
                    <span className="text-primary-700 font-semibold">{doc.type}</span>
                    <span className="text-black">{doc.period}</span>
                </li>
            ))}
        </ul>
    );
}
