import React, { useState, useEffect, useRef } from 'react';
import { MetricModal } from '../MetricModal/MetricModal';

interface RowData {
    ticker: string;
    company: string;
    stock_price: number;
    percent_change: number;
    sharesOutstanding: number;
    marketCap: number;
    enterpriseValue: number;
    cash: number;
    debt: number;
    ratioValue: number;
}

type SortConfig = { key: keyof RowData | 'selectedRatio'; direction: 'asc' | 'desc' };

interface WatchlistTableHeaderProps {
    selectedRatio: string;
    setSelectedRatio: (ratio: string) => void;
    onSort: (key: keyof RowData | 'selectedRatio') => void;
    sortConfig: SortConfig | null;
}

export const WatchlistTableHeader: React.FC<WatchlistTableHeaderProps> = ({ selectedRatio, setSelectedRatio, onSort, sortConfig }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const getSortIndicator = (key: keyof RowData | 'selectedRatio') => {
        if (!sortConfig || sortConfig.key !== key) {
            return null;
        }
        return (
            <span className="text-[0.65rem] text-gray-400">
                {sortConfig.direction === 'asc' ? '▲' : '▼'}
            </span>
        );
    };

    const headers = [
        { label: "Ticker", key: "ticker" as keyof RowData, sortable: true },
        { label: "Company", key: "company" as keyof RowData, sortable: true },
        { label: "Price", key: "stock_price" as keyof RowData, sortable: true },
        { label: "% Change", key: "percent_change" as keyof RowData, sortable: true },
        { label: "Shares Out", key: "sharesOutstanding" as keyof RowData, sortable: true },
        { label: "Market Cap", key: "marketCap" as keyof RowData, sortable: true },
        { label: "Enterprise Value", key: "enterpriseValue" as keyof RowData, sortable: true },
        { label: selectedRatio, key: "ratioValue" as keyof RowData, isRatio: true },
        { label: "Actions", key: "actions" as keyof RowData, isAction: true },
    ];

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const toggleDropdown = (e: React.MouseEvent) => {
        e.stopPropagation();
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleDropdownAction = (action: string, key: keyof RowData | 'selectedRatio') => {
        if (action === 'Sort') {
            onSort(key);
        } else if (action === 'Select Ratio') {
            handleOpenModal();
        }
        setIsDropdownOpen(false);
    };

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsDropdownOpen(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    return (
        <>
            <thead className="bg-gray-50 text-gray-500 text-sm">
                <tr>
                    <th scope="col" className="px-4 py-3">
                        <span className="sr-only">Expand/Collapse Row</span>
                    </th>
                    {headers.map(({ label, key, sortable, isAction, isRatio }) => (
                        <th
                            key={key}
                            className={`px-2 py-3 text-center relative
                                ${sortable ? 'cursor-pointer' : ''}
                                ${isAction ? 'text-gray-50 pointer-events-none select-none' : ''}
                                ${isRatio ? 'cursor-pointer text-primary-500' : ''}`}
                            onClick={sortable ? () => onSort(key) : isRatio ? toggleDropdown : undefined}
                        >
                            <div className="flex justify-center items-center space-x-1">
                                <span>{label}</span>
                                {sortable && getSortIndicator(key)}
                                {isRatio && isDropdownOpen && (
                                    <div ref={dropdownRef} className="absolute top-full mt-1 bg-white border border-gray-300 rounded shadow-lg">
                                        <div onClick={() => handleDropdownAction('Sort', 'selectedRatio')} className="text-gray-600 font-normal text-left px-4 py-2 cursor-pointer hover:bg-gray-100">Sort</div>
                                        <div onClick={() => handleDropdownAction('Select Ratio', 'selectedRatio')} className="text-gray-600 font-normal text-left px-4 py-2 cursor-pointer hover:bg-gray-100">Select Ratio</div>
                                    </div>
                                )}
                            </div>
                        </th>
                    ))}
                </tr>
            </thead>
            <MetricModal
                isOpen={isModalOpen}
                handleClose={handleCloseModal}
                selectedRatio={selectedRatio}
                setSelectedRatio={setSelectedRatio}
            />
        </>
    );
};
