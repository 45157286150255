import React from 'react';
import { NewsList } from "./NewsList";

interface InTheNewsProps {
    onSelect: (url: string, title: string) => void;
}

export const InTheNews: React.FC<InTheNewsProps> = ({ onSelect }) => {
    return (
        <div className="flex flex-col p-4 bg-white rounded-lg shadow-md">
            <div className="mb-4">
                <h4 className="text-xl font-semibold">News</h4>
                <p className="text-sm text-gray-500">Articles</p>
            </div>
            <NewsList onSelect={onSelect} />
        </div>
    );
}
