import { WatchlistRowType } from '../../types/WatchlistRowType';

interface RatioData {
    value: number;
    format: 'number' | 'percentage' | 'currency';
}

interface SortConfig {
  key: keyof WatchlistRowType | 'selectedRatio';
  direction: 'asc' | 'desc';
}

export const sortWatchlistData = (
  rows: WatchlistRowType[],
  sortConfig: SortConfig | null,
  ratios: { [ticker: string]: { [ratioName: string]: RatioData } },
  selectedRatio: string
): WatchlistRowType[] => {
  if (!sortConfig) return rows;

  return rows.sort((a, b) => {
    let aValue: any;
    let bValue: any;

    if (sortConfig.key === 'selectedRatio') {
      aValue = ratios[a.ticker]?.[selectedRatio]?.value ?? 0;
      bValue = ratios[b.ticker]?.[selectedRatio]?.value ?? 0;
    } else {
      aValue = a[sortConfig.key];
      bValue = b[sortConfig.key];
    }

    if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
    if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
    return 0;
  });
};
