import { useState } from "react";
import { CompanyTitle } from "../components/Common/CompanyTitle/CompanyTitle";
import { OptionsTable } from "../components/OptionsTable/OptionsTable";
import { useResearchTicker } from "../context/TickerContext";
import { LoadingTable } from "../components/Common/LoadingTable/LoadingTable";
import { MainContainer } from "../components/Containers/MainContainer";
import { OptionsTableControls } from "../components/OptionsTable/OptionsTableControls";
import { RiskFreeRateProvider } from "../context/RiskFreeRateContext";
import { useOptionChains } from "../context/OptionChainsContext";
import { useCompanyProfile } from "../context/CompanyProfileContext";

export const OptionsPage = () => {
    const { companyProfile } = useCompanyProfile();
    const { isLoading } = useOptionChains();
    const { researchTicker } = useResearchTicker();
    const [ minimumReturn, setMinimumReturn ] = useState<number>(0)
    const [ minimumDiscount, setMinimumDiscount ] = useState<number>(0)
    const [ minimumBid, setMinimumBid ] = useState<number>(0)

    return (
    <>
        <RiskFreeRateProvider>
        <MainContainer>
        <CompanyTitle className='text-2xl font-bold text-primary-600 mb-4' />
        <h1 className='text-xl font-semibold text-gray-700 mb-8'>Option Contracts</h1>
        <section className="flex items-center w-full">
            <div className="w-full">
                <OptionsTableControls setMinimumReturn={setMinimumReturn} setMinimumDiscount={setMinimumDiscount} setMinimumBid={setMinimumBid}/>
                <div className="flex flex-row gap-2">
                    <p className="ml-4 text-xl text-primary-700  font-semibold">{researchTicker}</p>
                    <p className="mb-4 text-gray-500 text-lg font-semibold">${companyProfile[0].price}</p>
                </div>
                { isLoading && <LoadingTable /> }
                { !isLoading && <OptionsTable minimumReturn={minimumReturn} minimumDiscount={minimumDiscount} minimumBid={minimumBid} />}
            </div>
        </section>
        </MainContainer>
        </RiskFreeRateProvider>
    </>
    );
}