import React, { useEffect, useState } from 'react';
import { MainContainer } from '../components/Containers/MainContainer';
import { CompanyTitle } from '../components/Common/CompanyTitle/CompanyTitle';
import { useResearchTicker } from '../context/TickerContext';
import { getCompanyNews } from '../api/stocknewsapi/getCompanyNews';
import { NewsArticle } from '../api/stocknewsapi/getCompanyNews';
import { NewsCard } from '../components/Common/NewsCard/NewsCard';

export const CompanyNewsPage: React.FC = () => {
  const { researchTicker } = useResearchTicker();
  const [news, setNews] = useState<NewsArticle[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchNews = async () => {
      if (!researchTicker) return;
      try {
        const data = await getCompanyNews(researchTicker);
        setNews(data); // Directly use the returned data array
        console.log(data);
      } catch (error) {
        console.error('Error fetching company news:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchNews();
  }, [researchTicker]);

  return (
    <MainContainer>
      <CompanyTitle className='text-2xl font-bold text-primary-600 mb-8' />
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div className="flex flex-col gap-4">
          {news.map((article, index) => (
            <NewsCard key={index} article={article} />
          ))}
        </div>
      )}
    </MainContainer>
  );
};
