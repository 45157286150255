import React, { useState } from 'react';
import { MainContainer } from '../components/Containers/MainContainer';
import { CompanyTitle } from '../components/Common/CompanyTitle/CompanyTitle';
import { AnnualFilings } from '../components/Common/DocumentCards/SECFilings/AnnualFilings';
import { CurrentReports } from '../components/Common/DocumentCards/SECFilings/CurrentReports';
import { OtherFilings } from '../components/Common/DocumentCards/SECFilings/OtherFilings';
import { EarningsTranscripts } from '../components/Common/DocumentCards/EarningsTranscripts/EarningsTranscripts';
import { InTheNews } from '../components/Common/DocumentCards/InTheNews';
import { DocumentViewer } from '../components/Common/DocumentViewer/DocumentViewer';

export const DocumentsPage: React.FC = () => {
    const [selectedDocument, setSelectedDocument] = useState<{ url: string, title: string } | null>(null);

    const handleDocumentSelect = (url: string, title: string) => {
        setSelectedDocument({ url, title });
    };

    return (
        <MainContainer>
            <CompanyTitle className='text-2xl font-bold text-primary-600 mb-4' />
            {selectedDocument ? (
                <DocumentViewer url={selectedDocument.url} title={selectedDocument.title} onClose={() => setSelectedDocument(null)} />
            ) : (
                <div className="grid grid-cols-3 gap-8">
                    <AnnualFilings onSelect={handleDocumentSelect} />
                    <CurrentReports onSelect={handleDocumentSelect} />
                    <OtherFilings onSelect={handleDocumentSelect} />
                    <EarningsTranscripts onSelect={handleDocumentSelect} />
                    <InTheNews onSelect={handleDocumentSelect} />
                </div>
            )}
        </MainContainer>
    );
};
