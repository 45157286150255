import React from 'react';

interface TableCellProps {
    content: React.ReactNode; // Updated to accept React elements
    className?: string;
}

export const TableCell: React.FC<TableCellProps> = ({ content, className }) => {
    return (
        <td className={`${className} px-2 py-2 whitespace-nowrap`}>
            {content}
        </td>
    );
};
