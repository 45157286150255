import React from 'react';

interface MainContainerProps {
    children: React.ReactNode;
    className?: string;
}

export const MainContainer: React.FC<MainContainerProps> = ({ children, className }) => {
    return (
        <div className={`${className} max-w-screen-xl flex flex-col mx-auto mt-16 mb-16 ml-72`}>
            {children}
        </div>
    );
};
