import React from 'react';
import { Logo } from '../../Common/Logo/Logo';
import { useAuth0 } from '@auth0/auth0-react';

export const MainNavBar: React.FC = () => {
  const { logout } = useAuth0();

  return (
    <header className="fixed top-0 left-0 w-full bg-gray-100 border-b border-gray-200 z-10 h-12">
      <nav className="h-full">
        <div className="flex justify-between items-center h-full px-8">
          <div className="flex items-center w-full lg:w-auto">
            <a href="https://www.enryched.com" className="flex items-center">
              <Logo />
            </a>
          </div>
          <div className="flex items-center lg:order-2">
            <button
              onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
              className="text-primary-600 ml-1 lg:ml-3 hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-3 lg:px-5 py-2 lg:py-2.5 focus:outline-none"
            >
              Log out
            </button>
            <button
              data-collapse-toggle="mobile-menu-2"
              type="button"
              className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
              aria-controls="mobile-menu-2"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </nav>
    </header>
  );
};
