import React from 'react';

interface InputBoxProps {
    placeholder?: string;
    label: string;
    onChange: (value: string) => void; // Callback accepts a string
}

export const InputBox: React.FC<InputBoxProps> = ({ placeholder, label, onChange }) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.value); // Call onChange callback with input value
    };

    return (
        <div className="w-full flex flex-col items-start">
            <label className="font-bold text-sm text-gray-700 mb-1">{label}</label>
            <input
                type="text"
                className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                placeholder={placeholder}
                onChange={handleChange}
            />
        </div>
    );
};
