import React from 'react';
import { AnnualFilingsList } from "./AnnualFilingsList";

interface AnnualFilingsProps {
    onSelect: (url: string, title: string) => void;
}

export const AnnualFilings: React.FC<AnnualFilingsProps> = ({ onSelect }) => {
    return (
        <div className="flex flex-col p-4 bg-white rounded-lg shadow-md">
            <div className="mb-4">
                <h4 className="text-xl font-semibold">Annual & Quarterly Reports</h4>
                <p className="text-sm text-gray-500">10-Ks & 10-Qs</p>
            </div>
            <AnnualFilingsList onSelect={onSelect} />
        </div>
    );
}
