// context/WatchlistContext/WatchlistTableRatioContext.tsx

import React, { createContext, useContext, useState, ReactNode } from 'react';

interface RatioData {
    value: number;
    format: 'number' | 'percentage' | 'currency';
}

interface WatchlistTableRatioContextType {
    ratios: { [ticker: string]: { [ratioName: string]: RatioData } };
    updateRatio: (ticker: string, ratioName: string, value: number, format: 'number' | 'percentage' | 'currency') => void;
}

const WatchlistTableRatioContext = createContext<WatchlistTableRatioContextType | undefined>(undefined);

export const WatchlistTableRatioProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [ratios, setRatios] = useState<{ [ticker: string]: { [ratioName: string]: RatioData } }>({});

    const updateRatio = (ticker: string, ratioName: string, value: number, format: 'number' | 'percentage' | 'currency') => {
        setRatios(prevRatios => ({
            ...prevRatios,
            [ticker]: {
                ...prevRatios[ticker],
                [ratioName]: { value, format }
            }
        }));
    };

    return (
        <WatchlistTableRatioContext.Provider value={{ ratios, updateRatio }}>
            {children}
        </WatchlistTableRatioContext.Provider>
    );
};

export const useWatchlistTableRatio = (): WatchlistTableRatioContextType => {
    const context = useContext(WatchlistTableRatioContext);
    if (!context) {
        throw new Error('useWatchlistTableRatio must be used within a WatchlistTableRatioProvider');
    }
    return context;
};
