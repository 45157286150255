import axios from 'axios';

const FMP_API_KEY = process.env.REACT_APP_FMP_API_KEY;

export const getAllTickers = async () => {
  try {
    const response = await axios.get(`https://financialmodelingprep.com/api/v3/stock/list?apikey=${FMP_API_KEY}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching tickers:', error);
    throw error;
  }
};
