type KeyMappingType = {
    [key: string]: string
};

const keyMapping: KeyMappingType = {
    revenue: 'Revenue',
    costOfRevenue: 'Cost of revenue',
    grossProfit: 'Gross profit',
    researchAndDevelopmentExpenses: 'Research and development expenses',
    generalAndAdministrativeExpenses: 'General and administrative expenses',
    sellingAndMarketingExpenses: 'Selling and marketing expenses',
    sellingGeneralAndAdministrativeExpenses: 'Selling general and administrative expenses',
    otherExpenses: 'Other expenses',
    operatingExpenses: 'Operating expenses',
    costAndExpenses: 'Cost and expenses',
    interestIncome: 'Interest income',
    interestExpense: 'Interest expense',
    depreciationAndAmortization: 'Depreciation and amortization',
    ebitda: 'EBITDA',
    operatingIncome: 'Operating income',
    totalOtherIncomeExpensesNet: 'Total other income expenses net',
    incomeBeforeTax: 'Income before tax',
    incomeTaxExpense: 'Income tax expense',
    netIncome: 'Net income',
    deferredIncomeTax: 'Deferred income tax',
    stockBasedCompensation: 'Stock based compensation',
    changeInWorkingCapital: 'Change in working capital',
    accountsReceivables: 'Accounts receivable',
    inventory: 'Inventory',
    accountsPayables: 'Accounts payables',
    otherWorkingCapital: 'Other working capital',
    otherNonCashItems: 'Other non cash items',
    netCashProvidedByOperatingActivities: 'Net cash provided by operating activities',
    investmentsInPropertyPlantAndEquipment: 'Investments in property plant and equipment',
    acquisitionsNet: 'Acquisitions net',
    purchasesOfInvestments: 'Purchases of investments',
    salesMaturitiesOfInvestments: 'Sales maturities of investments',
    otherInvestingActivites: 'Other investing activities',
    netCashUsedForInvestingActivites: 'Net cash used for investing activities',
    debtRepayment: 'Debt repayment',
    commonStockIssued: 'Common stock issued',
    commonStockRepurchased: 'Common stock repurchased',
    dividendsPaid: 'Dividends paid',
    otherFinancingActivites: 'Other financing activities',
    netCashUsedProvidedByFinancingActivities: 'Net cash used provided by financing activities',
    effectOfForexChangesOnCash: 'Effect of forex changes on cash',
    netChangeInCash: 'Net change in cash',
    cashAtEndOfPeriod: 'Cash at end of period',
    cashAtBeginningOfPeriod: 'Cash at beginning of period',
    operatingCashFlow: 'Operating cash flow',
    capitalExpenditure: 'Capital expenditure',
    freeCashFlow: 'Free cash flow',
    cashAndCashEquivalents: 'Cash and cash equivalents',
    shortTermInvestments: 'Short term investments',
    cashAndShortTermInvestments: 'Cash and short term investments',
    netReceivables: 'Net receivables',
    otherCurrentAssets: 'Other current assets',
    totalCurrentAssets: 'Total current assets',
    propertyPlantEquipmentNet: 'Property plant equipment net',
    goodwill: 'Goodwill',
    intangibleAssets: 'Intangible assets',
    goodwillAndIntangibleAssets: 'Goodwill and intangible assets',
    longTermInvestments: 'Long term investments',
    taxAssets: 'Tax assets',
    otherNonCurrentAssets: 'Other non current assets',
    totalNonCurrentAssets: 'Total non current assets',
    otherAssets: 'Other assets',
    totalAssets: 'Total assets',
    accountPayables: 'Account payables',
    shortTermDebt: 'Short term debt',
    taxPayables: 'Tax payables',
    deferredRevenue: 'Deferred revenue',
    otherCurrentLiabilities: 'Other current liabilities',
    totalCurrentLiabilities: 'Total current liabilities',
    longTermDebt: 'Long term debt',
    deferredRevenueNonCurrent: 'Deferred revenue non current',
    deferredTaxLiabilitiesNonCurrent: 'Deferred tax liabilities non current',
    otherNonCurrentLiabilities: 'Other non current liabilities',
    totalNonCurrentLiabilities: 'Total non current liabilities',
    otherLiabilities: 'Other liabilities',
    capitalLeaseObligations: 'Capital lease obligations',
    totalLiabilities: 'Total liabilities',
    preferredStock: 'Preferred stock',
    commonStock: 'Common stock',
    retainedEarnings: 'Retained earnings',
    accumulatedOtherComprehensiveIncomeLoss: 'Accumulated other comprehensive income',
    othertotalStockholdersEquity: 'Other total stockholders equity',
    totalStockholdersEquity: 'Total stockholders equity',
    totalEquity: 'Total equity',
    totalLiabilitiesAndStockholdersEquity: 'Total liabilities and stockholders equity',
    minorityInterest: 'Minority interest',
    totalLiabilitiesAndTotalEquity: 'Total liabilities and total equity',
    totalInvestments: 'Total investments',
    totalDebt: 'Total debt',
    netDebt: 'Net debt',
    grossProfitRatio: 'Gross profit ratio',
    operatingIncomeRatio: 'Operating income ratio',
    incomeBeforeTaxRatio: 'Income before tax ratio',
    netIncomeRatio: 'Net income ratio',
    eps: 'Earnings per share',
    epsDiluted: 'Earnings per share (diluted)',
    epsdiluted: 'Earnings per share (diluted)',
    ebitdaratio: 'EBITDA ratio',
    weightedAverageShsOut: 'Weighted avg shares outstanding',
    weightedAverageShsOutDil: 'Weighted avg shares outstanding (diluted)',
};

export function renameKey(originalKey: string): string {
    return keyMapping[originalKey] || originalKey;
}
