import { FMPIncomeStatementType } from "./FMPIncomeStatement";
import { FMPCashFlowStatementType } from "./FMPCashFlowStatement";
import { FMPBalanceSheetStatementType } from "./FMPBalanceSheetStatement";
import { FMPCompanyProfile } from "./FMPCompanyProfile";
import { CalculatedMetricTypes } from "./CalculatedMetricTypes";

// Utility type to extract numeric fields
type NumericFields<T> = {
  [K in keyof T]: T[K] extends number ? K : never;
}[keyof T];

type NumericIncomeFields = NumericFields<FMPIncomeStatementType>;
type NumericCashFlowFields = NumericFields<FMPCashFlowStatementType>;
type NumericBalanceSheetFields = NumericFields<FMPBalanceSheetStatementType>;
type NumericProfileFields = NumericFields<FMPCompanyProfile>;
type NumericCalculatedFields = NumericFields<CalculatedMetricTypes>;

type MetricFieldListType = {
    key: string;
    description: string;
    field: NumericIncomeFields | NumericCashFlowFields | NumericBalanceSheetFields | NumericProfileFields | NumericCalculatedFields;
    source: 'income' | 'cashFlow' | 'balanceSheet' | 'profile' | 'calculated';
};

export const MetricFieldList: MetricFieldListType[] = [
    // Income Statement Metrics
    { key: "Revenue", description: "Total income generated by the sale of goods or services related to the company's primary operations.", field: "revenue", source: 'income' },
    { key: "Cost of Revenue", description: "The total cost of manufacturing and delivering a product or service to consumers.", field: "costOfRevenue", source: 'income' },
    { key: "Gross Profit", description: "Revenue minus the cost of goods sold. It measures the efficiency of production and sales.", field: "grossProfit", source: 'income' },
    { key: "Research and Development Expenses", description: "Costs incurred in the process of discovering new products or services, and improving existing ones.", field: "researchAndDevelopmentExpenses", source: 'income' },
    { key: "General and Administrative Expenses", description: "Expenses related to the general operation of the company, excluding direct production costs.", field: "generalAndAdministrativeExpenses", source: 'income' },
    { key: "Selling and Marketing Expenses", description: "Costs associated with the promotion, distribution, and selling of products or services.", field: "sellingAndMarketingExpenses", source: 'income' },
    { key: "Selling General and Administrative Expenses", description: "Combined total of selling expenses and all general and administrative expenses.", field: "sellingGeneralAndAdministrativeExpenses", source: 'income' },
    { key: "Other Expenses", description: "Miscellaneous expenses that are not directly tied to the primary operations of the company.", field: "otherExpenses", source: 'income' },
    { key: "Operating Expenses", description: "Expenses required for the day-to-day functioning of the business, excluding cost of goods sold.", field: "operatingExpenses", source: 'income' },
    { key: "Cost and Expenses", description: "The aggregate total of all costs and expenses incurred by the company.", field: "costAndExpenses", source: 'income' },
    { key: "Interest Income", description: "Income earned from investments such as bonds, savings accounts, and other interest-bearing assets.", field: "interestIncome", source: 'income' },
    { key: "Interest Expense", description: "The cost incurred by the company for borrowed funds, including interest payments on debt.", field: "interestExpense", source: 'income' },
    { key: "Depreciation and Amortization", description: "The systematic allocation of the cost of tangible and intangible assets over their useful lives.", field: "depreciationAndAmortization", source: 'income' },
    { key: "EBITDA", description: "Earnings before interest, taxes, depreciation, and amortization. It measures overall financial performance.", field: "ebitda", source: 'income' },
    { key: "Operating Income", description: "Profit realized from the business's core operations, excluding deductions of interest and tax.", field: "operatingIncome", source: 'income' },
    { key: "Total Other Income/Expenses Net", description: "Net amount of income or expenses from activities not related to the core operations.", field: "totalOtherIncomeExpensesNet", source: 'income' },
    { key: "Income Before Tax", description: "Profit calculated after all expenses have been subtracted from revenue, but before tax expenses.", field: "incomeBeforeTax", source: 'income' },
    { key: "Income Tax Expense", description: "The total amount of tax that the company is obligated to pay to the government.", field: "incomeTaxExpense", source: 'income' },
    { key: "Net Income", description: "The total profit of the company after all expenses and taxes have been deducted from revenue.", field: "netIncome", source: 'income' },
    { key: "EPS", description: "Earnings per share, calculated as net income divided by the number of outstanding shares.", field: "eps", source: 'income' },
    { key: "EPS Diluted", description: "Earnings per share considering the potential dilution from convertible securities and options.", field: "epsdiluted", source: 'income' },
    { key: "Weighted Average Shares Outstanding", description: "The average number of shares outstanding during a period, weighted by the time they were outstanding.", field: "weightedAverageShsOut", source: 'income' },
    { key: "Diluted Weighted Average Shares Outstanding", description: "The average number of shares outstanding during a period, considering the effect of dilutive securities.", field: "weightedAverageShsOutDil", source: 'income' },
    
    // Cash Flow Statement Metrics
    { key: "Deferred Income Tax", description: "Deferred income tax expense or benefit.", field: "deferredIncomeTax", source: 'cashFlow' },
    { key: "Stock Based Compensation", description: "Compensation expense related to stock-based awards.", field: "stockBasedCompensation", source: 'cashFlow' },
    { key: "Change In Working Capital", description: "Change in the net working capital of the company.", field: "changeInWorkingCapital", source: 'cashFlow' },
    { key: "Accounts Receivables", description: "Changes in accounts receivables.", field: "accountsReceivables", source: 'cashFlow' },
    { key: "Accounts Payables", description: "Changes in accounts payables.", field: "accountsPayables", source: 'cashFlow' },
    { key: "Other Working Capital", description: "Changes in other working capital items.", field: "otherWorkingCapital", source: 'cashFlow' },
    { key: "Other Non Cash Items", description: "Changes in other non-cash items.", field: "otherNonCashItems", source: 'cashFlow' },
    { key: "Net Cash Provided By Operating Activities", description: "Net cash flow from operating activities.", field: "netCashProvidedByOperatingActivities", source: 'cashFlow' },
    { key: "Investments In Property Plant And Equipment", description: "Capital expenditures on property, plant, and equipment.", field: "investmentsInPropertyPlantAndEquipment", source: 'cashFlow' },
    { key: "Acquisitions Net", description: "Cash spent on acquisitions, net of cash acquired.", field: "acquisitionsNet", source: 'cashFlow' },
    { key: "Purchases Of Investments", description: "Cash spent on purchasing investments.", field: "purchasesOfInvestments", source: 'cashFlow' },
    { key: "Sales Maturities Of Investments", description: "Cash received from sales or maturities of investments.", field: "salesMaturitiesOfInvestments", source: 'cashFlow' },
    { key: "Other Investing Activities", description: "Other cash flows from investing activities.", field: "otherInvestingActivites", source: 'cashFlow' },
    { key: "Net Cash Used For Investing Activities", description: "Net cash flow from investing activities.", field: "netCashUsedForInvestingActivites", source: 'cashFlow' },
    { key: "Debt Repayment", description: "Cash used to repay debt.", field: "debtRepayment", source: 'cashFlow' },
    { key: "Common Stock Issued", description: "Cash received from issuing common stock.", field: "commonStockIssued", source: 'cashFlow' },
    { key: "Common Stock Repurchased", description: "Cash used to repurchase common stock.", field: "commonStockRepurchased", source: 'cashFlow' },
    { key: "Dividends Paid", description: "Cash used to pay dividends.", field: "dividendsPaid", source: 'cashFlow' },
    { key: "Other Financing Activities", description: "Other cash flows from financing activities.", field: "otherFinancingActivites", source: 'cashFlow' },
    { key: "Net Cash Used Provided By Financing Activities", description: "Net cash flow from financing activities.", field: "netCashUsedProvidedByFinancingActivities", source: 'cashFlow' },
    { key: "Effect Of Forex Changes On Cash", description: "Effect of foreign exchange rate changes on cash.", field: "effectOfForexChangesOnCash", source: 'cashFlow' },
    { key: "Net Change In Cash", description: "Net change in cash and cash equivalents.", field: "netChangeInCash", source: 'cashFlow' },
    { key: "Cash At End Of Period", description: "Cash and cash equivalents at the end of the period.", field: "cashAtEndOfPeriod", source: 'cashFlow' },
    { key: "Cash At Beginning Of Period", description: "Cash and cash equivalents at the beginning of the period.", field: "cashAtBeginningOfPeriod", source: 'cashFlow' },
    { key: "Operating Cash Flow", description: "Cash generated from normal business operations.", field: "operatingCashFlow", source: 'cashFlow' },
    { key: "Capital Expenditure", description: "Funds used by a company to acquire or upgrade physical assets such as property, industrial buildings, or equipment.", field: "capitalExpenditure", source: 'cashFlow' },
    { key: "Free Cash Flow", description: "Cash generated by the company after accounting for capital expenditures.", field: "freeCashFlow", source: 'cashFlow' },

    // Balance Sheet Metrics
    { key: "Cash and Cash Equivalents", description: "Cash and cash equivalents available to the company.", field: "cashAndCashEquivalents", source: 'balanceSheet' },
    { key: "Short Term Investments", description: "Investments that are expected to be converted to cash within a year.", field: "shortTermInvestments", source: 'balanceSheet' },
    { key: "Cash and Short Term Investments", description: "Combined total of cash and short-term investments.", field: "cashAndShortTermInvestments", source: 'balanceSheet' },
    { key: "Net Receivables", description: "The amount of money owed to the company by its customers, net of allowances for doubtful accounts.", field: "netReceivables", source: 'balanceSheet' },
    { key: "Inventory", description: "The value of the company's raw materials, work-in-progress goods, and finished goods.", field: "inventory", source: 'balanceSheet' },
    { key: "Other Current Assets", description: "Other assets expected to be converted to cash within a year.", field: "otherCurrentAssets", source: 'balanceSheet' },
    { key: "Total Current Assets", description: "The total value of all current assets.", field: "totalCurrentAssets", source: 'balanceSheet' },
    { key: "Property Plant Equipment Net", description: "The net value of property, plant, and equipment after depreciation.", field: "propertyPlantEquipmentNet", source: 'balanceSheet' },
    { key: "Goodwill", description: "The value of the company's brand name, customer relationships, and other intangible assets.", field: "goodwill", source: 'balanceSheet' },
    { key: "Intangible Assets", description: "Non-physical assets such as patents and trademarks.", field: "intangibleAssets", source: 'balanceSheet' },
    { key: "Goodwill and Intangible Assets", description: "The combined value of goodwill and intangible assets.", field: "goodwillAndIntangibleAssets", source: 'balanceSheet' },
    { key: "Long Term Investments", description: "Investments that are expected to be held for more than a year.", field: "longTermInvestments", source: 'balanceSheet' },
    { key: "Tax Assets", description: "Assets related to tax payments.", field: "taxAssets", source: 'balanceSheet' },
    { key: "Other Non Current Assets", description: "Other long-term assets not classified elsewhere.", field: "otherNonCurrentAssets", source: 'balanceSheet' },
    { key: "Total Non Current Assets", description: "The total value of all non-current assets.", field: "totalNonCurrentAssets", source: 'balanceSheet' },
    { key: "Other Assets", description: "Miscellaneous assets not classified elsewhere.", field: "otherAssets", source: 'balanceSheet' },
    { key: "Total Assets", description: "The total value of everything a company owns and uses to conduct its business.", field: "totalAssets", source: 'balanceSheet' },
    { key: "Account Payables", description: "The amount of money the company owes to its suppliers.", field: "accountPayables", source: 'balanceSheet' },
    { key: "Short Term Debt", description: "Debt that is due within one year.", field: "shortTermDebt", source: 'balanceSheet' },
    { key: "Tax Payables", description: "Taxes owed by the company to the government.", field: "taxPayables", source: 'balanceSheet' },
    { key: "Deferred Revenue", description: "Revenue received but not yet earned.", field: "deferredRevenue", source: 'balanceSheet' },
    { key: "Other Current Liabilities", description: "Other liabilities expected to be settled within a year.", field: "otherCurrentLiabilities", source: 'balanceSheet' },
    { key: "Total Current Liabilities", description: "The total value of all current liabilities.", field: "totalCurrentLiabilities", source: 'balanceSheet' },
    { key: "Long Term Debt", description: "Debt that is due in more than one year.", field: "longTermDebt", source: 'balanceSheet' },
    { key: "Deferred Revenue Non Current", description: "Long-term deferred revenue.", field: "deferredRevenueNonCurrent", source: 'balanceSheet' },
    { key: "Deferred Tax Liabilities Non Current", description: "Long-term deferred tax liabilities.", field: "deferredTaxLiabilitiesNonCurrent", source: 'balanceSheet' },
    { key: "Other Non Current Liabilities", description: "Other long-term liabilities not classified elsewhere.", field: "otherNonCurrentLiabilities", source: 'balanceSheet' },
    { key: "Total Non Current Liabilities", description: "The total value of all non-current liabilities.", field: "totalNonCurrentLiabilities", source: 'balanceSheet' },
    { key: "Other Liabilities", description: "Miscellaneous liabilities not classified elsewhere.", field: "otherLiabilities", source: 'balanceSheet' },
    { key: "Capital Lease Obligations", description: "The total amount of capital lease obligations.", field: "capitalLeaseObligations", source: 'balanceSheet' },
    { key: "Total Liabilities", description: "The total amount of financial obligations and debts the company owes.", field: "totalLiabilities", source: 'balanceSheet' },
    { key: "Preferred Stock", description: "The value of preferred stock issued by the company.", field: "preferredStock", source: 'balanceSheet' },
    { key: "Common Stock", description: "The value of common stock issued by the company.", field: "commonStock", source: 'balanceSheet' },
    { key: "Retained Earnings", description: "The total amount of net income retained by the company and not paid out as dividends.", field: "retainedEarnings", source: 'balanceSheet' },
    { key: "Accumulated Other Comprehensive Income Loss", description: "The accumulated balance of other comprehensive income (OCI) items.", field: "accumulatedOtherComprehensiveIncomeLoss", source: 'balanceSheet' },
    { key: "Other Total Stockholders Equity", description: "Miscellaneous items included in total stockholders' equity.", field: "othertotalStockholdersEquity", source: 'balanceSheet' },
    { key: "Total Stockholders Equity", description: "The total value of shareholders' equity in the company.", field: "totalStockholdersEquity", source: 'balanceSheet' },
    { key: "Total Equity", description: "The total value of the company's equity.", field: "totalEquity", source: 'balanceSheet' },
    { key: "Total Liabilities And Stockholders Equity", description: "The total value of liabilities and stockholders' equity combined.", field: "totalLiabilitiesAndStockholdersEquity", source: 'balanceSheet' },
    { key: "Minority Interest", description: "The value of minority interests in the company.", field: "minorityInterest", source: 'balanceSheet' },
    { key: "Total Liabilities And Total Equity", description: "The total value of liabilities and equity combined.", field: "totalLiabilitiesAndTotalEquity", source: 'balanceSheet' },
    { key: "Total Investments", description: "The total value of the company's investments.", field: "totalInvestments", source: 'balanceSheet' },
    { key: "Total Debt", description: "The total amount of debt the company owes.", field: "totalDebt", source: 'balanceSheet' },
    { key: "Net Debt", description: "The total debt minus cash and cash equivalents.", field: "netDebt", source: 'balanceSheet' },

    // Company Profile Metrics
    { key: "Market Cap", description: "The total market value of a company's outstanding shares.", field: "mktCap", source: 'profile' },
    { key: "Number of Employees", description: "The total number of employees working for the company.", field: "fullTimeEmployees", source: 'profile' },
    
    // Calculated Metrics
    { key: "Enterprise Value", description: "The market capitalization of the business less cash and investments plus debt.", field: "enterpriseValue", source: 'calculated' },
  ];
