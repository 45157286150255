import React, { useState } from 'react';
import { MetricModalHeader } from './MetricModalHeader';
import { CreateNewRatio } from './CreateNewRatio';
import { SelectRatio } from './SelectRatio';
import { ModalPop } from './ModalPop';

type TabKey = 'ratios' | 'new_ratio';

interface MetricModalProps {
  isOpen: boolean;
  handleClose: () => void;
  selectedRatio: string;
  setSelectedRatio: (ratio: string) => void;
}

export const MetricModal: React.FC<MetricModalProps> = ({ isOpen, handleClose, selectedRatio, setSelectedRatio }) => {
  const [activeTab, setActiveTab] = useState<TabKey>('ratios');

  const isActive = (tabName: TabKey) =>
    activeTab === tabName
      ? 'text-indigo-600 border-b-2 border-indigo-600 rounded-t-lg active'
      : 'text-gray-500 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300';

  return (
    <ModalPop isOpen={isOpen} handleClose={handleClose}>
      <div className="sticky top-0 bg-white z-10">
        <MetricModalHeader handleModalToggle={handleClose} activeTab={activeTab} setActiveTab={setActiveTab} isActive={isActive} />
      </div>
      <div className="overflow-y-auto max-h-[70vh]">
        {activeTab === 'ratios' && <SelectRatio selectedRatio={selectedRatio} setSelectedRatio={setSelectedRatio} handleClose={handleClose} />}
        {activeTab === 'new_ratio' && <CreateNewRatio />}
      </div>
    </ModalPop>
  );
};
