import React, { useState, KeyboardEvent, useRef, useEffect } from 'react';
import axios from 'axios';
import { SolidButton } from '../Common/Buttons/SolidButton';
import { useWatchlist } from '../../context/WatchlistContext/WatchlistContext';
import { doesTickerExist } from '../../utils/dataProcessing/doesTickerExist/doesTickerExist';
import { cleanTicker } from '../../utils/formatting/cleanTickers/cleanTicker';
import { TickerPill } from '../Common/TickerPill/TickerPill';
import { API_URL } from '../../services/config/apiConfig';

interface AddTickerModalProps {
    onClose: () => void;
}

export const AddTickerModal: React.FC<AddTickerModalProps> = ({ onClose }) => {
    const { watchlist, setWatchlist } = useWatchlist();
    const [inputValue, setInputValue] = useState('');
    const [tickers, setTickers] = useState<string[]>([]);
    const [successMessage, setSuccessMessage] = useState('');

    const inputRef = useRef<HTMLInputElement>(null);

    const addTicker = (ticker: string) => {
        const cleanedTicker = cleanTicker(ticker);
        if (cleanedTicker && !doesTickerExist(cleanedTicker, watchlist.tickers)) {
            if (!doesTickerExist(cleanedTicker, tickers)) {
                setTickers([...tickers, cleanedTicker]);
                setInputValue('');
            }
        } else {
            setSuccessMessage(`Ticker "${cleanedTicker}" already exists in the watchlist.`);
            setTimeout(() => setSuccessMessage(''), 3000);
        }
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            addTicker(inputValue);
        } else if (event.key === 'Backspace' && inputValue === '') {
            event.preventDefault();
            removeLastTicker();
        }
    };

    const removeLastTicker = () => {
        if (tickers.length > 0) {
            setTickers(tickers.slice(0, -1));
        }
    };

    const handleRemoveTicker = (tickerToRemove: string) => {
        setTickers(tickers.filter(ticker => ticker !== tickerToRemove));
    };

    const handleAddTickers = async () => {
        if (tickers.length > 0) {
            const uniqueNewTickers = tickers.filter(ticker => !doesTickerExist(ticker, watchlist.tickers));

            if (uniqueNewTickers.length < tickers.length) {
                setSuccessMessage('Some tickers already exist in the watchlist.');
                setTimeout(() => setSuccessMessage(''), 3000);
                return;
            }

            if (uniqueNewTickers.length > 0) {
                const updatedWatchlist = {
                    ...watchlist,
                    tickers: [...watchlist.tickers, ...uniqueNewTickers],
                };
                setWatchlist(updatedWatchlist);
                setTickers([]);
                try {
                    await axios.post(`${API_URL}/api/post/watchlist`, updatedWatchlist);
                    setSuccessMessage(`Successfully added ${uniqueNewTickers.join(', ')} to the watchlist!`);
                    setTimeout(() => setSuccessMessage(''), 3000);
                } catch (error) {
                    console.error('Error saving watchlist:', error);
                }
            } else {
                setSuccessMessage('No new tickers to add.');
                setTimeout(() => setSuccessMessage(''), 3000);
            }
        }
    };

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [tickers]);

    return (
        <div className="flex flex-col gap-4">
            <h2 className="text-xl font-semibold">Add Tickers</h2>
            <div className="flex flex-wrap gap-2 w-full border-2 border-gray-300 rounded p-2">
                {tickers.map(ticker => (
                    <TickerPill key={ticker} ticker={ticker} onRemove={handleRemoveTicker} />
                ))}
                <input
                    ref={inputRef}
                    type="text"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={handleKeyDown}
                    placeholder="Enter tickers"
                    className="flex-grow border-none focus:outline-none"
                    style={{ minWidth: '150px', padding: '6px 8px' }}
                />
            </div>
            <div className="flex flex-row gap-4">
                <SolidButton content="Add Tickers" onClick={handleAddTickers} />
                {successMessage && <span className="text-primary-500 mt-2">{successMessage}</span>}
            </div>
        </div>
    );
};
