interface SolidButtonProps {
    content: string;
    className?: string;
    onClick: () => void;
}

export const SolidButton: React.FC<SolidButtonProps> = ({ content, className, onClick }) => {
    return (
        <button className={`text-white bg-primary-700 hover:bg-primary-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center ${className}`} onClick={onClick}>
            {content}
        </button>
    );
}
