// selectRatio.ts
import { FinancialDataTypes } from '../../../types/FinancialDataTypes';
import { calculateRatio } from './calculateRatio';
import { RatioConfig } from '../../../types/RatioConfig';

interface RatioParams {
  ratioConfigs: RatioConfig[];
  financialData: FinancialDataTypes;
  ratioName: string;
  netCost?: number;
}

export const selectRatio = ({ ratioConfigs, financialData, ratioName, netCost }: RatioParams) => {
  try {
    const config = ratioConfigs.find(config => config.name === ratioName);
    
    if (config) {
      const { numerator, denominator, format } = config;
      const result = calculateRatio(financialData, numerator, denominator, format, netCost);
      return { ...result, format }; // Ensure format is included
    } else {
      throw new Error(`Unsupported ratio type: ${ratioName}`);
    }
  } catch (error) {
    console.error(`Error calculating ${ratioName}:`, error);
    throw error;
  }
};
