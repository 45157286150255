import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useWatchlist } from '../../context/WatchlistContext/WatchlistContext';
import { API_URL } from '../../services/config/apiConfig';

interface RowOptionsProps {
    ticker: string;
}

export const RowOptions: React.FC<RowOptionsProps> = ({ ticker }) => {
    const { watchlist, setWatchlist } = useWatchlist();
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleDelete = async (e: React.MouseEvent) => {
        e.stopPropagation();
        const tickerIndex = watchlist.tickers.indexOf(ticker);
        if (tickerIndex > -1) {
            const updatedTickers = [...watchlist.tickers];
            updatedTickers.splice(tickerIndex, 1);
            const updatedWatchlist = { ...watchlist, tickers: updatedTickers };
            setWatchlist(updatedWatchlist);
            try {
                await axios.post(`${API_URL}/api/post/watchlist`, updatedWatchlist);
            } catch (error) {
                console.error('Error saving watchlist:', error);
            }
        }
        setShowDropdown(false);
    };

    const toggleDropdown = (e: React.MouseEvent) => {
        e.stopPropagation();
        setShowDropdown(!showDropdown);
    };

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setShowDropdown(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    return (
        <td className="p-1 relative text-right pr-4">
            <button className="text-gray-500 hover:text-gray-800" onClick={toggleDropdown}>
                <span className="material-icons text-md">more_vert</span>
            </button>
            {showDropdown && (
                <div ref={dropdownRef} className="absolute right-0 px-2 py-2 w-48 bg-white rounded-md shadow-xl z-10">
                    <button className="w-full text-left block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 rounded-md" onClick={handleDelete}>
                        Delete
                    </button>
                </div>
            )}
        </td>
    );
};