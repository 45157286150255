import React, { createContext, useContext, useState, ReactNode } from 'react';

interface NetCostContextProps {
  netCost: number;
  setNetCost: React.Dispatch<React.SetStateAction<number>>;
}

const NetCostContext = createContext<NetCostContextProps | undefined>(undefined);

export const NetCostProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [netCost, setNetCost] = useState<number>(0);

  return (
    <NetCostContext.Provider value={{ netCost, setNetCost }}>
      {children}
    </NetCostContext.Provider>
  );
};

export const useNetCost = () => {
  const context = useContext(NetCostContext);
  if (!context) {
    throw new Error('useNetCost must be used within a NetCostProvider');
  }
  return context;
};
