import React from 'react';
import { Dropdown } from '../Dropdowns/Dropdown';
import { PeriodDropdown } from '../Dropdowns/PeriodDropdown';
import { PeriodType } from '../../../types/PeriodType';

interface BarChartControllerProps {
    units: string;
    metric: string;
    period: string;
    yearType: string;
    onMetricChange: (value: string) => void;
    onPeriodChange: (value: PeriodType) => void;
    onYearTypeChange: (value: string) => void;
}

export const BarChartController: React.FC<BarChartControllerProps> = ({
    units,
    metric,
    period,
    yearType,
    onMetricChange,
    onPeriodChange,
    onYearTypeChange,
}) => {
  return (
    <div className="flex flex-row justify-between items-start">
        <div className='items-start ml-8 mt-4'>
            <Dropdown value={metric} onChange={onMetricChange} options={['Revenue', 'Net Income', 'Cash Flow']} className='font-md text-primary-700' />
            <p className='text-sm'>{units}</p>
        </div>
        <div className="flex flex-row gap-4 mt-4 mr-8">
            <PeriodDropdown value={period} onChange={onPeriodChange} options={['annual', 'quarterly', 'ttm']} className='text-sm' />
            <Dropdown value={yearType} onChange={onYearTypeChange} options={['Fiscal Year', 'Calendar Year']} className='text-sm' />
        </div>
    </div>
  );
};

export default BarChartController;
