import { MainContainer } from "../components/Containers/MainContainer";
import { CompanyTitle } from "../components/Common/CompanyTitle/CompanyTitle";

export const OverviewPage = () => {

    return (
    <>
        <MainContainer>
        <CompanyTitle className='text-2xl font-bold text-primary-600 mb-4' />
        <h1>Company Overview</h1>
        </MainContainer>
    </>
    );
}