import React, { useState, useEffect, useRef } from 'react';
import { useWatchlist } from '../../context/WatchlistContext/WatchlistContext';
import { WatchlistType } from '../../types/WatchlistType';
import { WatchlistDropdownList } from './WatchlistDropdownList';
import { CreateNewWatchlist } from './CreateNewWatchlist';
import { NewWatchlistModal } from './NewWatchlistModal';
import { Modal } from '../Common/Modal/Modal'; // Ensure correct import path

export const WatchlistManagement: React.FC = () => {
    const { watchlist, setWatchlist } = useWatchlist();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const watchlists: WatchlistType[] = [
        { id: '1', watchlistName: 'Selective Companies', tickers: [], userId: 'user1' },
        { id: '2', watchlistName: 'Healthcare Stocks', tickers: [], userId: 'user1' },
        { id: '3', watchlistName: 'Finance Stocks', tickers: [], userId: 'user1' },
    ];

    const handleWatchlistSelect = (selectedWatchlist: WatchlistType) => {
        setWatchlist(selectedWatchlist);
        setIsDropdownOpen(false);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsDropdownOpen(false);
        }
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            setIsDropdownOpen(false);
        }
    };

    const handleOpenModal = () => {
        setIsDropdownOpen(false);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <div className="relative" ref={dropdownRef}>
            <div
                className="flex items-center justify-between border px-4 py-1 gap-4 w-[520px] cursor-pointer rounded-lg"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
                <h2 className="text-lg font-bold text-primary-600">{watchlist.watchlistName}</h2>
                <svg data-accordion-icon="" className="w-4 h-4 shrink-0" fill="currentColor" aria-hidden="true" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
            </div>
            {isDropdownOpen && (
                <div className="absolute z-10 bg-white border mt-1 w-[520px] shadow-lg">
                    <ul>
                        <CreateNewWatchlist setIsDropdownOpen={setIsDropdownOpen} handleOpenModal={handleOpenModal} />
                        <WatchlistDropdownList 
                            watchlists={watchlists} 
                            handleWatchlistSelect={handleWatchlistSelect} 
                        />
                    </ul>
                </div>
            )}
            <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
                <NewWatchlistModal onClose={handleCloseModal} />
            </Modal>
        </div>
    );
};
