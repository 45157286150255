import React, { useState, useEffect } from 'react';
import { TableCell } from '../Common/TableCell/TableCell';
import { addCommas } from '../../utils/formatting/addCommas/addCommas';
import { ExpandedRow } from '../Common/ExpandedRow/ExpandedRow';
import { RowOptions } from './RowOptions';
import { selectRatio } from '../../utils/math/ratios/selectRatio';
import { useGetCompanyFinancials } from '../../utils/hooks/useGetCompanyFinancials';
import { useGetRatioConfigs } from '../../utils/hooks/useGetRatioConfigs';
import { calculateMetrics } from '../../utils/math/calculateMetrics';
import { useWatchlistTableRatio } from '../../context/WatchlistContext/WatchlistTableRatioContext';
import { FinancialDataTypes } from '../../types/FinancialDataTypes';
import { formatToPrecision } from '../../utils/formatting/formatToPrecision/formatToPrecision';

interface WatchlistTableRowProps {
    ticker: string;
    company: string;
    stock_price: number;
    percent_change: number;
    sharesOutstanding: number;
    marketCap: number;
    enterpriseValue: number;
    selectedRatio: string;
}

export const WatchlistTableRow: React.FC<WatchlistTableRowProps> = ({
    ticker, company, stock_price, percent_change, sharesOutstanding, marketCap, enterpriseValue, selectedRatio
}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isCalculating, setIsCalculating] = useState(false);

    const { ratios, updateRatio } = useWatchlistTableRatio();

    const { incomeData, cashFlowData, balanceSheetData, companyProfile, isLoading } = useGetCompanyFinancials({ ticker, period: 'quarterly' });
    const { ratioConfigs } = useGetRatioConfigs();

    useEffect(() => {
        const calculateRatio = async () => {
            if (incomeData && cashFlowData && balanceSheetData && companyProfile && !isLoading && ratioConfigs.length > 0) {
                try {
                    setIsCalculating(true);
                    const calculatedMetrics = calculateMetrics(incomeData, cashFlowData, balanceSheetData, companyProfile);
                    const financialData: FinancialDataTypes = {
                        incomeData,
                        cashFlowData,
                        balanceSheetData,
                        companyProfile,
                        calculatedMetrics,
                        isLoading,
                    };

                    const result = selectRatio({ ratioConfigs, financialData, ratioName: selectedRatio });
                    if (result) {
                        updateRatio(ticker, selectedRatio, result.value, result.format);
                    }
                } catch (error) {
                    console.error(`Error calculating ratio for ${ticker}:`, error);
                } finally {
                    setIsCalculating(false);
                }
            }
        };

        calculateRatio();
    }, [ticker, incomeData, cashFlowData, balanceSheetData, companyProfile, isLoading, selectedRatio, ratioConfigs, updateRatio]);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const ratioData = ratios[ticker]?.[selectedRatio];
    const ratioResult = ratioData ? ratioData.value : 0;
    const format = ratioData ? ratioData.format : 'number';

    const formatRatio = (value: number, format: 'number' | 'percentage' | 'currency'): string => {
        let formattedValue: string;
        switch (format) {
            case 'percentage':
                formattedValue = `${formatToPrecision(value * 100, 3).toFixed(2)}%`;
                break;
            case 'currency':
                formattedValue = `$${formatToPrecision(value, 3).toFixed(2)}`;
                break;
            case 'number':
            default:
                formattedValue = formatToPrecision(value, 3).toFixed(1);
                break;
        }
        return formattedValue;
    };

    return (
        <>
            <tr className={`${isExpanded ? 'bg-primary-100' : 'bg-white hover:bg-gray-100'} cursor-pointer transition`} onClick={toggleExpand}>
                <td className="w-4 pl-2">
                    <div className="flex items-center justify-center">
                        <svg
                            data-accordion-icon=""
                            className={`w-4 h-4 transition-transform duration-500 transform ${isExpanded ? 'rotate-180' : 'rotate-0'}`}
                            style={{ transformOrigin: '65% 50%' }}
                            fill="currentColor"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                        </svg>
                    </div>
                </td>
                <TableCell className="text-gray-700" content={ticker} />
                <TableCell className="text-left" content={company} />
                <TableCell className="text-center" content={`$${stock_price.toFixed(2)}`} />
                <TableCell className={`${percent_change > 0 ? 'text-green-700' : 'text-red-700'} text-center`} content={`${percent_change.toFixed(2)}%`} />
                <TableCell className="text-center" content={addCommas(sharesOutstanding.toString())} />
                <TableCell className="text-center" content={`$${addCommas(marketCap.toFixed(1))}B`} />
                <TableCell className="text-center" content={`$${addCommas(enterpriseValue.toFixed(1))}B`} />
                <TableCell className="w-32 text-center" content={
                    isCalculating ? (
                        <svg className="animate-spin h-5 w-5 text-gray-500 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
                        </svg>
                    ) : (
                        formatRatio(ratioResult, format)
                    )
                } />
                <RowOptions ticker={ticker} />
            </tr>
            {isExpanded && (
                <tr className="bg-primary-100">
                    <td colSpan={11}>
                        <div className="p-4">
                            <ExpandedRow ticker={ticker} />
                        </div>
                    </td>
                </tr>
            )}
        </>
    );
};
